import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {CohortUserDeletedEventStruct} from '@cohort/shared/apps/cohort/userEvents/userDeleted';
import {CohortUserDeletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userDeleted';
import {Trans} from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => (
  <Trans i18nKey="userEvents.user.deleted.detail" ns="app-cohort" />
);

export const CohortUserDeletedUserEvent: UserEvent<CohortUserDeletedEventStruct> = {
  spec: CohortUserDeletedEventSpec,
  detailComponent: DetailComponent,
};
