import Button from '@cohort/merchants/components/buttons/Button';
import CohortFormQuestionItem from '@cohort/merchants/components/form/cohortForm/CohortFormQuestionItem';
import CohortFormQuestionSheet from '@cohort/merchants/components/form/cohortForm/CohortFormQuestionSheet';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getDefinedLanguages} from '@cohort/merchants/lib/form/localization';
import type {CohortFormQuestion} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';
import {PlusCircle} from '@phosphor-icons/react';
import {Fragment, useEffect, useRef, useState} from 'react';
import type {
  ArrayPath,
  Control,
  FieldArray,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import {useController, useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type FormModalState = {
  open: boolean;
  question?: CohortFormQuestion;
  questionIdx?: number;
};

type CohortFormConfigProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  fieldPaths: {
    questions: Path<T>;
    description: Path<T>;
    selectedLanguage?: Path<T>;
    definedLanguages?: Path<T>;
  };
  existingConfig?: unknown;
};

const CohortFormConfig = <T extends FieldValues>({
  register,
  control,
  fieldPaths,
  existingConfig,
}: CohortFormConfigProps<T>): React.ReactNode => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {keyPrefix: 'form.cohortForm.CohortFormConfig'});
  const {field: definedLanguagesField} = useController({
    control,
    name: fieldPaths.definedLanguages ?? ('definedLanguages' as Path<T>),
  });
  const {fieldState: questionsFieldState} = useController({control, name: fieldPaths.questions});
  const errorRef = useRef<HTMLDivElement>(null);

  const [formModal, setFormModal] = useState<FormModalState>({
    open: false,
    question: undefined,
    questionIdx: undefined,
  });

  function closeModal(): void {
    setFormModal({open: false, question: undefined});
  }

  const {fields, append, remove, update, replace} = useFieldArray({
    name: fieldPaths.questions as ArrayPath<T>,
    control,
  });

  const selectedLanguage = useWatch({
    control,
    name: fieldPaths.selectedLanguage ?? ('selectedLanguage' as Path<T>),
  });

  useEffect(() => {
    if (questionsFieldState.error && errorRef.current) {
      errorRef.current.scrollIntoView();
    }
  }, [questionsFieldState.error]);

  // update defined languages regarding the initial form configuration
  useEffect(() => {
    const config = CohortFormConfigSchema.safeParse(existingConfig);
    const questions = config.success ? config.data.questions : null;
    const description = config.success ? config.data.description : {};
    const questionsNames = [...(questions ?? []).map(question => question.name)];
    const questionsOptionsLabels = [
      ...(questions ?? []).map(question => (question.options ?? []).map(option => option.label)),
    ].flat();

    // used a set to remove duplications
    const newDefinedLanguages = new Set([
      ...definedLanguagesField.value,
      ...getDefinedLanguages(merchant.defaultLanguage, [
        description,
        ...questionsNames,
        ...questionsOptionsLabels,
      ]),
    ]);

    definedLanguagesField.onChange(Array.from(newDefinedLanguages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <Fragment>
      <LocalizedTextEditorInput
        name={fieldPaths.description}
        label={t('label')}
        placeholder={t('placeholder')}
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
      <div className="flex w-full flex-col">
        <label className="mb-1 block text-sm font-medium text-slate-700">
          {t('titleQuestions')}
        </label>
        {fields.length === 0 && (
          <div
            ref={errorRef}
            className="rounded-md border-2 border-dashed py-2 text-center text-sm font-medium text-slate-500"
          >
            {t('placeholderNoQuestion')}
          </div>
        )}
        <DraggableList
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          handleOnReorder={ids => replace(ids.map(id => fields.find(field => field.id === id)!))}
          items={fields.map((q, index) => {
            // the fields items are typed as FieldArrayWithId<T, ArrayPath<T>, "id"> and are not directly assignable to CohortFormQuestion
            const question = q as unknown as CohortFormQuestion;
            return {
              item: (
                <CohortFormQuestionItem
                  key={question.id}
                  question={question}
                  handleEdit={() => {
                    setFormModal({
                      open: true,
                      question,
                      questionIdx: index,
                    });
                  }}
                  handleRemove={isDefaultLanguageSelected ? () => remove(index) : undefined}
                  selectedLanguage={selectedLanguage}
                />
              ),
              id: question.id,
            };
          })}
        />
        {questionsFieldState.error && (
          <p className="text-sm text-red-500" data-testid="integration.config.questions-error">
            {t('errorEmpty')}
          </p>
        )}
        {isDefaultLanguageSelected && (
          <Button
            onClick={() => setFormModal({open: true, question: undefined})}
            data-testid="add-question"
            variant="ghost"
            className="mt-1 w-fit"
          >
            <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonNewQuestion')}
          </Button>
        )}
      </div>
      {formModal.open && (
        <CohortFormQuestionSheet
          title={formModal.question ? t('updateQuestionTitle') : t('addQuestionTitle')}
          question={formModal.question}
          onClose={closeModal}
          onSave={question => {
            formModal.question
              ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                update(formModal.questionIdx!, question as FieldArray<T, ArrayPath<T>>)
              : append(question as FieldArray<T, ArrayPath<T>>);
            closeModal();
          }}
          selectedLanguage={selectedLanguage}
        />
      )}
    </Fragment>
  );
};

export default CohortFormConfig;
