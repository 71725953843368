import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const ShopifySyncConfigSchema = z.object({});
export type ShopifySyncConfig = z.infer<typeof ShopifySyncConfigSchema>;

export const ShopifySyncStateSchema = z.object({
  lastUpdatedAt: z.string().nullable(),
});
export type ShopifySyncState = z.infer<typeof ShopifySyncStateSchema>;

export const ShopifyExportUserConfigSchema = z.object({
  createCustomers: z.boolean(),
  createCustomerSegments: z.boolean(),
});
export type ShopifyExportUserConfig = z.infer<typeof ShopifyExportUserConfigSchema>;

/**
 * customerIdAttributeLastCreatedAt: The creation date of the last customer synced
 * cohortsLastUpdatedAt: The date of last user-event cohort.joined or cohort.left synced
 * cohortsSegmentIds: The customer segments created for each Cohort. Record<Cohort['referenceId'], SegmentId (external)>
 */
export const ShopifyExportUserStateSchema = z.object({
  customerIdAttributeLastCreatedAt: z.string().nullable(),
  cohortsLastUpdatedAt: z.string().nullable(),
  cohortsSegmentIds: z.record(z.string(), z.string().optional()),
});
export type ShopifyExportUserState = z.infer<typeof ShopifyExportUserStateSchema>;

export type ShopifySyncStruct = SyncIntegrationStruct<
  ShopifySyncConfig,
  ShopifySyncState,
  never,
  never,
  ShopifyExportUserConfig,
  ShopifyExportUserState
>;

export const ShopifySyncIntegrationSpec: SyncIntegrationSpec<ShopifySyncStruct> = {
  userSyncsConfigSchema: ShopifySyncConfigSchema,
  userSyncsStateSchema: ShopifySyncStateSchema,
  contentSyncConfigSchema: null,
  userEventExportConfigSchema: null,
  userExportConfigSchema: ShopifyExportUserConfigSchema,
  userExportStateSchema: ShopifyExportUserStateSchema,
};
