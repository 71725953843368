import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortChallengeLeftPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  resourceDeleted: z.boolean(),
});
export type CohortChallengeLeftProperties = z.infer<typeof CohortChallengeLeftPropertiesSchema>;

export type CohortChallengeLeftEventStruct = UserEventStruct<
  'cohort.challenge.left',
  keyof CohortChallengeLeftProperties,
  CohortChallengeLeftProperties,
  CohortChallengeLeftProperties
>;

export const CohortChallengeLeftEventSpec: UserEventSpec<CohortChallengeLeftEventStruct> = {
  id: 'cohort.challenge.left',
  description: 'Event Triggerred when a user leaves a Challenge',
  propertiesSchema: CohortChallengeLeftPropertiesSchema,
  exposedPropertiesSchema: CohortChallengeLeftPropertiesSchema,
  resources: {
    campaignId: 'cohort.challenge',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['campaignId'],
  },
};
