import Button from '@cohort/merchants/components/buttons/Button';
import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import {SlideOverCropImage} from '@cohort/merchants/components/form/SlideOverCropImage';
import Loader from '@cohort/merchants/components/Loader';
import type {FormField, UploadedFileTypes} from '@cohort/merchants/lib/form/utils';
import {getAcceptFileType, getFileType} from '@cohort/merchants/lib/form/utils';
import {isImageTooSmall, isSquaredVideo} from '@cohort/merchants/lib/form/utils';
import type {ImageToCrop} from '@cohort/merchants/lib/ImageCrop';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import type {SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS, getMaxFileSize} from '@cohort/shared/utils/fileUploads';
import {getImageUrl, getVideoUrl, isRoundedVisual, Sizes} from '@cohort/shared/utils/media';
import {isImageFile, isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {File, FileArrowUp, Image, Trash, VideoCamera} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isString} from 'remeda';

type FileInputProps<T extends FieldValues> = JSX.IntrinsicElements['input'] &
  WrappedField &
  FormField<T> & {
    acceptHint?: string;
    assetKind: AssetKind;
    withResize?: boolean;
  };

type FileInputPreviewProps = {
  fileInputValue: File | string;
  name: string;
  fileType: UploadedFileTypes;
  assetKind: AssetKind;
};

const uploadIcon = {
  image: <Image className="h-12 w-12" />,
  video: <VideoCamera className="h-12 w-12" />,
  document: <FileArrowUp className="h-12 w-12" />,
};

const FileInputPreview: React.FC<FileInputPreviewProps> = ({
  fileInputValue,
  name,
  fileType,
  assetKind,
}) => {
  if (fileType === 'document') {
    return <File className="h-12 w-12 text-primary" />;
  }

  if (isFile(fileInputValue)) {
    if (fileType === 'video') {
      return (
        <video
          className={cn(assetKind === 'perkVideo' && 'aspect-video bg-black')}
          src={URL.createObjectURL(fileInputValue)}
          width="200"
          height="150"
        />
      );
    }
    return (
      <img
        src={URL.createObjectURL(fileInputValue)}
        width="200"
        height="150"
        alt="image"
        className={cn('rounded-md', isRoundedVisual(assetKind) && 'rounded-full')}
        data-testid={`${name}-preview`}
      />
    );
  }

  if (getFileType(fileInputValue) === 'video') {
    return (
      <video
        className={cn(assetKind === 'perkVideo' && 'aspect-video bg-black')}
        src={getVideoUrl(import.meta.env.COHORT_ENV, fileInputValue, {
          h: Sizes.S,
          w: Sizes.S,
        })}
        width="200"
        height="150"
      />
    );
  }

  return (
    <img
      src={getImageUrl(import.meta.env.COHORT_ENV, fileInputValue, {
        h: Sizes.S,
        w: Sizes.S,
      })}
      width="200"
      height="150"
      alt="image"
      className={cn('rounded-md', isRoundedVisual(assetKind) && 'rounded-full')}
      data-testid={`${name}-preview`}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function FileInput<T extends FieldValues>({
  register,
  control,
  label,
  description,
  rules,
  acceptHint,
  assetKind,
  withResize,
  ...props
}: FileInputProps<T>): JSX.Element {
  const {t} = useTranslation('components', {keyPrefix: 'form.v2.fileInput'});

  const {field, fieldState} = useController({
    control,
    name: props.name,
  });
  const [imageToCrop, setImageToCrop] = useState<ImageToCrop>();
  const [isCropLoading, setIsCropLoading] = useState(false);

  const currentFile = field.value;
  const acceptTypes = props.accept?.split(',').map(type => type.trim());
  const acceptFileType = getAcceptFileType(acceptTypes);
  const fileType = getFileType(currentFile);

  async function validateAndSetFile(file: File): Promise<void> {
    // Can happen when drag & dropping a file of invalid type
    if (!acceptTypes?.includes(file.type)) {
      return notifyError(undefined, t('errorInvalidFileType'));
    }

    if (file.size > getMaxFileSize(assetKind, file.type).size) {
      return notifyError(
        undefined,
        `${t('errorFileTooLarge')} ${getMaxFileSize(assetKind, file.type).label}`
      );
    }

    if (
      isVideoFile(file.type) &&
      ['challengeVisual', 'digitalAssetVisual'].includes(assetKind) &&
      (await isSquaredVideo(file)) === false
    ) {
      return notifyError(undefined, t('errorVideoNotSquared'));
    }

    if (withResize && isImageFile(file.type)) {
      const fileUrl = URL.createObjectURL(file);
      const minDimensions = Object.keys(ASSETS_MIN_DIMENSIONS).includes(assetKind)
        ? ASSETS_MIN_DIMENSIONS[assetKind as SizedAssetKind]
        : undefined;

      if (minDimensions && (await isImageTooSmall(fileUrl, minDimensions))) {
        return notifyError(
          undefined,
          t('errorImageTooSmall', {
            width: minDimensions.width,
            height: minDimensions.height,
          })
        );
      }
      return setImageToCrop({
        name: file.name,
        src: fileUrl,
      });
    }
    return field.onChange(file);
  }

  return (
    <Fragment>
      {imageToCrop && (
        <SlideOverCropImage
          open={true}
          assetKind={assetKind}
          imageToCrop={imageToCrop}
          onClose={() => setImageToCrop(undefined)}
          onSubmit={(croppedImage: File) => {
            setImageToCrop(undefined);
            field.onChange(croppedImage);
          }}
          setIsCropLoading={setIsCropLoading}
        />
      )}
      <FieldWrapper
        label={label}
        name={props.name}
        description={description}
        error={fieldState.error?.message}
        optional={props.optional}
      >
        {isString(currentFile) || isFile(currentFile) ? (
          <div className="flex">
            <div className="flex items-center rounded-md bg-slate-100 p-5">
              <div>
                <FileInputPreview
                  fileInputValue={currentFile}
                  name={props.name}
                  fileType={fileType}
                  assetKind={assetKind}
                />
              </div>

              <Button
                className="ml-5"
                onClick={() => field.onChange(null)}
                variant="secondary"
                data-testid={`${props.name}-delete`}
                disabled={props.disabled}
              >
                <Trash className="-ml-1 mr-2 h-5 w-5" />
                {t('buttonDelete')}
              </Button>
            </div>
          </div>
        ) : (
          <div
            id="fileUploadInput"
            className={cn(
              'border-slate-e9 relative flex justify-center rounded-md border-2 border-dashed text-center text-slate-400',
              props.disabled && 'bg-slate-100'
            )}
          >
            <div className="flex w-full flex-col items-center">
              <Fragment>
                <input
                  className={cn(
                    'absolute left-0 top-0 h-full w-full opacity-0 ring-opacity-100',
                    props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  )}
                  data-testid={props.name}
                  {...register(props.name, rules)}
                  {...props}
                  type="file"
                  onChange={async e => {
                    e.stopPropagation();
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0] as File;
                      validateAndSetFile(file);

                      // Reset the input value to allow selecting the same file again
                      e.target.value = '';
                    }
                  }}
                  disabled={props.disabled}
                  value={undefined}
                />
                {isCropLoading && (
                  <div className="absolute left-0 top-0 flex h-full w-full cursor-not-allowed items-center justify-center rounded-md bg-white">
                    <Loader size={60} color="gray" />
                  </div>
                )}
                <div className="flex w-full flex-col items-center space-y-1 rounded-md p-5 ring-primary">
                  {uploadIcon[acceptFileType]}
                  <div className="flex text-sm font-medium text-slate-700">
                    <span className="text-primary-darker">{t('buttonUpload')}</span>
                    <span>&nbsp;{t('labelDragDrop')}</span>
                  </div>
                  {acceptHint !== undefined && (
                    <p className="text-slate-80 text-xs">{acceptHint}</p>
                  )}
                </div>
              </Fragment>
            </div>
          </div>
        )}
      </FieldWrapper>
    </Fragment>
  );
}
