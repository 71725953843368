import {
  Breadcrumb,
  BreadcrumbContent,
  BreadcrumbLink,
  BreadcrumbTitle,
} from '@cohort/merchants/components/Breadcrumb';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {getAppsRoute} from '@cohort/merchants/lib/Pages';
import React from 'react';
import {useTranslation} from 'react-i18next';

const AppPageBreadcrumb: React.FC = () => {
  const {app, appSpec} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.appPageBreadcrumb'});

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getAppsRoute().path} />
        <BreadcrumbTitle className="flex items-center gap-2">
          {React.cloneElement(app.logo, {height: 24, width: 24})}
          {appSpec.name}
        </BreadcrumbTitle>
      </BreadcrumbContent>
    </Breadcrumb>
  );
};

export default AppPageBreadcrumb;
