import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useEffect} from 'react';

type PageLayoutProps = {
  title?: string;
  breadcrumb?: React.ReactNode;
  menu?: React.ReactNode;
  rightAside?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  breadcrumb,
  menu,
  children,
  rightAside,
  footer,
}) => {
  useEffect(() => {
    document.title = title ? `Cohort | ${title}` : 'Cohort';
  }, [title]);

  return (
    <div
      className="grid h-full [grid-template-rows:min-content_1fr_min-content]"
      style={{
        background: menu
          ? 'linear-gradient(90deg, var(--layout-secondary-background-color) 50%, var(--layout-main-background-color) 100%)'
          : 'var(--layout-main-background-color)',
        gridTemplateAreas: `
        "breadcrumb"
        "body"
        "footer"
      `,
      }}
    >
      {breadcrumb ? breadcrumb : <div></div>}
      <div className="w-full overflow-auto">
        <div className="mx-auto h-full max-w-[var(--content-max-width)]">
          <div
            className={cn(
              'grid h-full',
              menu
                ? '[grid-template-columns:var(--aside-menu-width)_1fr_min-content]'
                : 'grid-cols-1 grid-rows-1'
            )}
            style={{
              gridTemplateAreas: `"menu content rightAside"`,
            }}
          >
            {menu && <div className="border-r bg-slate-50 pl-8 pr-4 pt-8">{menu}</div>}
            <div className="h-full overflow-auto bg-white p-8 pb-4">{children}</div>
            {rightAside && <div className="overflow-auto bg-white p-8 pl-4">{rightAside}</div>}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="mx-auto h-full max-w-[var(--content-max-width)]">
          <div className={cn(menu ? 'ml-[var(--aside-menu-width)]' : '')}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
