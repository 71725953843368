import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import Dropdown, {
  DropdownButton,
  DropdownEntry,
  DropdownPanel,
} from '@cohort/merchants/components/buttons/Dropdown';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {deleteDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {
  trackDigitalAssetCollectionDeleteSuccess,
  trackDigitalAssetCollectionDropdownClicked,
  trackDigitalAssetCollectionDropdownDeleteClicked,
  trackDigitalAssetCollectionDropdownDuplicateClicked,
} from '@cohort/merchants/lib/Tracking';
import DuplicateDigitalAssetCollectionModal from '@cohort/merchants/pages/digital-asset-collections/overview/DuplicateDigitalAssetCollectionModal';
import {Copy, DotsThreeVertical, Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

interface DigitalAssetCollectionDropdownProps {
  digitalAssetCollection: DigitalAssetCollectionAnalyticsADto;
}

const DigitalAssetCollectionDropdown: React.FC<DigitalAssetCollectionDropdownProps> = ({
  digitalAssetCollection,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.digitalAssetCollectionDropdown',
  });

  const {mutate: handleDigitalAssetCollectionDelete} = useCohortMutation({
    mutationFn: async () => deleteDigitalAssetCollection(merchantId, digitalAssetCollection.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(digitalAssetCollectionsKeys.digitalAssetCollections);
      trackDigitalAssetCollectionDeleteSuccess(digitalAssetCollection);
    },
  });

  return (
    <div>
      <Dropdown>
        <DropdownButton
          className="p-0 [height:36px] [width:36px]"
          onClick={() => trackDigitalAssetCollectionDropdownClicked(digitalAssetCollection)}
        >
          <DotsThreeVertical size={20} color="gray" />
        </DropdownButton>
        <DropdownPanel>
          {digitalAssetCollection.campaignsCount < 1 && (
            <DropdownEntry
              label={t('labelDelete')}
              icon={<Trash className="h-4 w-4 text-red-500" />}
              handleClick={() => {
                trackDigitalAssetCollectionDropdownDeleteClicked(digitalAssetCollection);
                setShowDeleteModal(true);
              }}
            />
          )}
          <DropdownEntry
            label={t('labelDuplicate')}
            icon={<Copy className="h-4 w-4 text-slate-400" />}
            handleClick={() => {
              trackDigitalAssetCollectionDropdownDuplicateClicked(digitalAssetCollection);
              setShowDuplicateModal(true);
            }}
          />
        </DropdownPanel>
      </Dropdown>
      {showDeleteModal && (
        <DeletionModal
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalSubtitle')}
          onClose={() => setShowDeleteModal(false)}
          show={true}
          onDelete={handleDigitalAssetCollectionDelete}
        />
      )}
      {showDuplicateModal && (
        <DuplicateDigitalAssetCollectionModal
          onClose={() => setShowDuplicateModal(false)}
          digitalAssetCollection={digitalAssetCollection}
        />
      )}
    </div>
  );
};

export default DigitalAssetCollectionDropdown;
