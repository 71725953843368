import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import {CampaignStatusBadge} from '@cohort/merchants/components/campaigns/CampaignStatusBadge';
import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignDropdown from '@cohort/merchants/pages/campaigns/overview/CampaignDropdown';
import {CampaignInternalNameFormSchema} from '@cohort/merchants/pages/campaigns/overview/CreateCampaignModal';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const CampaignPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageBreadcrumb'});
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();

  const {mutate: updateCampaignInternalName} = useCohortMutation({
    mutationFn: async (name: string) =>
      patchCampaign(merchantId, campaign.id, {internalName: name}),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getCampaignsRoute().path} />
        <BreadcrumbEditableTitle
          value={campaign.internalName}
          onChange={(name: string) => updateCampaignInternalName(name)}
          validator={(internalName: string) =>
            CampaignInternalNameFormSchema.safeParse({internalName}).success
          }
        />
        <BreadcrumbBadges>
          {campaign.type ? (
            <CampaignTypeBadge key="type" type={campaign.type} />
          ) : (
            <Fragment key="type" />
          )}
          <CampaignStatusBadge key="status" size="small" status={campaign.status} />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <CampaignDropdown campaign={campaign} />
    </Breadcrumb>
  );
};

export default CampaignPageBreadcrumb;
