import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Dropdown, {
  DropdownButton,
  DropdownEntry,
  DropdownPanel,
} from '@cohort/merchants/components/buttons/Dropdown';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {ChallengeParticipationKeys} from '@cohort/merchants/hooks/api/ChallengeParticipations';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userEventsKeys} from '@cohort/merchants/hooks/api/UserEvents';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  archiveCampaign,
  deleteCampaign,
  endCampaign,
  reopenCampaign,
  unarchiveCampaign,
} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import {
  trackCampaignDropdownArchiveClicked,
  trackCampaignDropdownClicked,
  trackCampaignDropdownUnarchiveClicked,
  trackCampaignDropdownVisitPageClicked,
} from '@cohort/merchants/lib/Tracking';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {
  Archive,
  ArrowSquareOut,
  DotsThreeVertical,
  LockSimple,
  LockSimpleOpen,
  Trash,
} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface CampaignDropdownProps {
  campaign: CampaignADto;
}

const CampaignDropdown: React.FC<CampaignDropdownProps> = ({campaign}) => {
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.campaignDropdown'});

  async function onMutationSuccess(): Promise<void> {
    await queryClient.invalidateQueries(campaignsKeys.campaigns);
  }

  const {mutate: handleCampaignArchive} = useCohortMutation({
    mutationFn: async () => archiveCampaign(merchantId, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignUnarchive} = useCohortMutation({
    mutationFn: async () => unarchiveCampaign(merchantId, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignEnd} = useCohortMutation({
    mutationFn: async () => endCampaign(merchantId, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignDelete} = useCohortMutation({
    mutationFn: async () => deleteCampaign(merchantId, campaign.id),
    onSuccess: async () => {
      setShowDeletionModal(false);
      navigate(getCampaignsRoute().path);
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      queryClient.invalidateQueries(userEventsKeys.userEvents);
      if (campaign.type === 'challenge') {
        queryClient.invalidateQueries(ChallengeParticipationKeys.participations);
      }
    },
    notifySuccessMessage: t('notificationCampaignDeleteSuccess'),
  });

  const {mutate: handleCampaignReopen} = useCohortMutation({
    mutationFn: async () => reopenCampaign(merchantId, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
    onError: err => {
      if (isCohortError(err, 'campaign.not-enough-tokens')) {
        return notify('error', t('notificationCampaignNotEnoughTokens'));
      }
      return notify('error', t('notificationCampaignUpdateError'));
    },
  });

  return (
    <Fragment>
      <Dropdown>
        <DropdownButton
          className="p-0 [height:36px] [width:36px]"
          onClick={() => trackCampaignDropdownClicked(campaign)}
        >
          <DotsThreeVertical size={20} color="gray" />
        </DropdownButton>
        <DropdownPanel>
          {campaign.status !== 'draft' && campaign.type !== 'airdrop' && (
            <DropdownEntry
              label={t('buttonVisitPage')}
              icon={<ArrowSquareOut className="h-4 w-4 text-slate-400" />}
              handleClick={() => {
                trackCampaignDropdownVisitPageClicked(campaign);
                if (campaign.store?.shopUrl) {
                  return window.open(campaign.store.shopUrl, '_blank');
                }
                if (campaign.challenge?.spaceUrl) {
                  return window.open(campaign.challenge.spaceUrl, '_blank');
                }
              }}
            />
          )}
          <DropdownEntry
            label={campaign.isArchived ? t('buttonUnarchive') : t('buttonArchive')}
            icon={<Archive className="h-4 w-4 text-slate-400" />}
            handleClick={() => {
              if (campaign.isArchived) {
                trackCampaignDropdownUnarchiveClicked(campaign);
                handleCampaignUnarchive();
              } else {
                trackCampaignDropdownArchiveClicked(campaign);
                handleCampaignArchive();
              }
            }}
          />
          <DropdownEntry
            label={t('buttonDelete')}
            icon={<Trash className="h-4 w-4 text-red-500" />}
            handleClick={() => {
              setShowDeletionModal(true);
            }}
          />
          {campaign.status !== 'draft' && (
            <DropdownEntry
              label={campaign.status === 'ended' ? t('buttonReopen') : t('buttonEnd')}
              icon={
                campaign.status === 'ended' ? (
                  <LockSimpleOpen className="h-4 w-4 text-slate-400" />
                ) : (
                  <LockSimple className="h-4 w-4 text-slate-400" />
                )
              }
              handleClick={() => {
                campaign.status === 'ended' ? handleCampaignReopen() : handleCampaignEnd();
              }}
            />
          )}
        </DropdownPanel>
      </Dropdown>
      {showDeletionModal && (
        <DeletionModal
          title={t('deletionModalTitle')}
          subtitle={t('deletionModalSubtitle')}
          show={showDeletionModal}
          onClose={() => setShowDeletionModal(false)}
          onDelete={handleCampaignDelete}
        />
      )}
    </Fragment>
  );
};

export default CampaignDropdown;
