import FileInput from '@cohort/merchants/components/form/FileInput';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {useFormContext} from 'react-hook-form';

const CohortImageMediaConfigComponent: React.FC = () => {
  const {control, register} = useFormContext<ContentSettingsStepValues>();

  return (
    <FileInput
      name="media.config.imageFileKey"
      assetKind="contentImage"
      register={register}
      control={control}
      accept={AllowedAssetMimeTypes.contentImage.options.join(',')}
      withResize
    />
  );
};

export default CohortImageMediaConfigComponent;
