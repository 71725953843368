import Button from '@cohort/merchants/components/buttons/Button';
import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import type {FormField, RadioCardOption} from '@cohort/merchants/lib/form/utils';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CheckCircle} from '@phosphor-icons/react';
import {Fragment} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type RadioCardIconBadgeProps = {
  icon: JSX.Element;
};

const RadioCardIconBadge: React.FC<RadioCardIconBadgeProps> = ({icon}) => (
  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-slate-100">{icon}</div>
);

const RadioCardTopRightCheck: React.FC = () => (
  <div className="absolute left-6 top-6 rounded-full bg-white">
    <CheckCircle weight="fill" className="h-5 w-5 text-primary-darker" />
  </div>
);

type RadioCardsProps<T extends FieldValues> = JSX.IntrinsicElements['input'] &
  WrappedField &
  FormField<T> & {
    direction?: 'row' | 'column';
    alignment?: 'items-start' | 'items-center';
    labelClassName?: string;
    options: RadioCardOption[];
    withChangeBtn?: {
      label: string;
    };
    withCheckIcon?: boolean;
    checkIconComponent?: JSX.Element;
    preventFieldOnChange?: boolean;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioCards<T extends FieldValues>({
  register,
  control,
  rules,
  direction = 'row',
  alignment = 'items-center',
  labelClassName,
  options,
  description,
  withChangeBtn,
  withCheckIcon = true,
  checkIconComponent,
  disabled,
  optional,
  preventFieldOnChange = false,
  className,
  ...props
}: RadioCardsProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name: props.name,
  });

  return (
    <FieldWrapper
      label={props.label}
      name={props.name}
      description={description}
      error={fieldState.error?.message}
      optional={optional}
    >
      <div className={cn('flex gap-4', direction === 'row' ? 'flex-wrap' : 'flex-col', className)}>
        {options.map(option => (
          <div key={option.value} className="grow basis-0">
            <Tooltip
              contentAsChild
              content={option.tooltipContent}
              disabled={option.tooltipContent === undefined}
            >
              <div
                className={cn(
                  'relative flex-1 cursor-pointer rounded-lg border bg-white',
                  String(field.value) === option.value
                    ? 'border-primary ring-2 ring-primary'
                    : 'border-border',
                  (option.disabled || disabled) && '!cursor-not-allowed bg-slate-50 opacity-50'
                )}
              >
                <label
                  className={cn(
                    'flex h-full cursor-pointer justify-between gap-4 p-4',
                    (option.disabled || disabled) && 'cursor-not-allowed',
                    alignment,
                    labelClassName
                  )}
                  htmlFor={`${props.name}.${option.value}`}
                >
                  {option.prefix && option.prefix}
                  <div className="flex-1 flex-col gap-2 text-sm">
                    <span className="font-medium">{option.label}</span>
                    {option.description && (
                      <span className="block text-slate-600">{option.description}</span>
                    )}
                  </div>
                  {option.suffix && option.suffix}
                  {field.value === option.value && (
                    <Fragment>
                      {withChangeBtn ? (
                        <Button
                          variant="secondary"
                          disabled={disabled}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            field.onChange(null);
                            // We need to manually uncheck the radio button as it's not a built-in
                            // behavior in HTML
                            const element = document.getElementById(
                              `${props.name}.${option.value}`
                            ) as HTMLInputElement | null;

                            if (element) {
                              element.checked = false;
                            }
                          }}
                        >
                          {withChangeBtn.label}
                        </Button>
                      ) : (
                        withCheckIcon &&
                        (checkIconComponent || (
                          <CheckCircle
                            className="h-5 w-5 self-center text-primary-darker"
                            aria-hidden="true"
                          />
                        ))
                      )}
                    </Fragment>
                  )}
                </label>
                {/*
                  absolute positioning and opacity-0 to have it existing in the page
                  but not visible nor interactive. We use this trick to enable focus
                  on form validation
                */}
                <input
                  className="pointer-events-none absolute opacity-0"
                  data-testid={props.name}
                  {...register(props.name, rules)}
                  {...props}
                  ref={field.ref}
                  id={`${props.name}.${option.value}`}
                  type="radio"
                  value={option.value}
                  onBlur={undefined}
                  onChange={e => {
                    props.onChange?.(e);
                    if (!preventFieldOnChange) {
                      field.onChange(e);
                    }
                  }}
                  disabled={option.disabled || disabled}
                />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
}

export {RadioCards, RadioCardIconBadge, RadioCardTopRightCheck};
