import type {MediaSpec} from '@cohort/shared/apps/media';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {z} from 'zod';

export const CohortLinkMediaConfigSchema = z.object({
  url: UrlInputSchema,
});
export type CohortLinkMediaConfig = z.infer<typeof CohortLinkMediaConfigSchema>;

export type CohortLinkMediaStruct = {
  Kind: 'cohort.link';
  Config: CohortLinkMediaConfig;
};

export const CohortLinkMediaSpec: MediaSpec<CohortLinkMediaStruct> = {
  kind: 'cohort.link',
  configSchema: CohortLinkMediaConfigSchema,
};
