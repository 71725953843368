import Button from '@cohort/merchants/components/buttons/Button';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Popover, Transition} from '@headlessui/react';
import React from 'react';

type DropdownEntryProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string | JSX.Element;
  icon: JSX.Element;
  handleClick?: () => void;
  testId?: string;
};

export const DropdownEntry: React.FC<DropdownEntryProps> = ({
  label,
  icon,
  disabled = false,
  handleClick,
  testId,
}) => (
  <button
    data-testid={testId}
    className={cn(
      'flex w-full items-center gap-4 text-nowrap p-2 pl-4 text-left text-sm text-gray-700 hover:bg-slate-100',
      disabled && 'cursor-not-allowed opacity-50'
    )}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      handleClick?.();
    }}
    disabled={disabled}
  >
    {icon}
    <p>{label}</p>
  </button>
);

type DropdownPanelProps = React.ComponentProps<typeof Popover.Panel>;

export const DropdownPanel: React.FC<DropdownPanelProps> = ({children, className, ...props}) => {
  return (
    <Transition
      className="relative z-30"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        className={cn(
          'absolute right-0 mt-2 rounded-md border bg-white shadow-md [min-width:200px]',
          className
        )}
        as="ul"
        {...props}
      >
        {children}
      </Popover.Panel>
    </Transition>
  );
};

type DropdownButton = React.ComponentProps<typeof Button>;

export const DropdownButton: React.FC<DropdownButton> = ({children, ...props}) => (
  <Popover.Button data-testid="dropdown-button" as="div">
    <Button variant="secondary" {...props}>
      {children}
    </Button>
  </Popover.Button>
);

type DropdownProps = React.ComponentProps<typeof Popover.Panel>;

export const Dropdown: React.FC<DropdownProps> = ({className, children, ...props}) => (
  <Popover open className={cn('relative', className)} {...props}>
    {children}
  </Popover>
);

export default Dropdown;
