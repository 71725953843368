import type {SalesforceUserObject} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  getExternalIdFieldsFromObject,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/syncs/edit/utils';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type ExportUsersExternaiIdFieldSelectProps = {salesforceUserObjects: SalesforceUserObject[]};
const ExportUsersExternaiIdFieldSelect: React.FC<ExportUsersExternaiIdFieldSelectProps> = ({
  salesforceUserObjects,
}) => {
  const {setValue, register, control, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.exportUsersExternalIdFieldSelect',
  });

  const isExportEnabled = watch('userExportConfig');
  const salesforceObjectName = watch('userSyncConfig.salesforceObjectName');

  const selectedObject = getSalesforceObjectByName(salesforceUserObjects, salesforceObjectName);

  const externalIdFieldOptions =
    selectedObject !== undefined
      ? getExternalIdFieldsFromObject(selectedObject).map(field => ({
          label: field.label,
          value: field.name,
        }))
      : [];

  const handleChange = (option: SelectOption | null): void => {
    if (option === null) {
      return;
    }

    setValue('userExportConfig.externalIdFieldName', option.value.toString());
  };

  return (
    <SelectInput
      name="userExportConfig.externalIdFieldName"
      placeholder={t('placeholder')}
      register={register}
      control={control}
      label={t('label')}
      options={externalIdFieldOptions}
      disabled={!isExportEnabled || selectedObject === undefined}
      onChange={handleChange}
    />
  );
};

export default ExportUsersExternaiIdFieldSelect;
