// i18nOwl-ignore [app-salesforce.description]
import type {App} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import translationEn from '@cohort/merchants/apps/salesforce/locales/en.json';
import translationFr from '@cohort/merchants/apps/salesforce/locales/fr.json';
import SalesforceEnvironmentPicker from '@cohort/merchants/apps/salesforce/SalesforceEnvironmentPicker';
import {SalesforceSyncIntegration} from '@cohort/merchants/apps/salesforce/sync/SyncIntegration';
import type {SalesforceAppStruct} from '@cohort/shared/apps/salesforce';
import {SalesforceAppSpec} from '@cohort/shared/apps/salesforce';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const SalesforceApp: App<SalesforceAppStruct> = {
  spec: SalesforceAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('salesforce', {
    preConnectionConfigComponent: SalesforceEnvironmentPicker,
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="salesforce" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: SalesforceSyncIntegration,
};
export default SalesforceApp;
