import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useDigitalAssetCollectionPageStore} from '@cohort/merchants/hooks/stores/digitalAssetCollectionPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import {CurrentDigitalAssetCollectionProvider} from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/CurrentDigitalAssetCollectionContext';
import DigitalAssetCollectionPageBreadcrumb from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionPageBreadcrumb';
import DigitalAssetCollectionPageMenu from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionPageMenu';

const DigitalAssetCollectionPageSkeleton: React.FC = () => {
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const footer = useDigitalAssetCollectionPageStore(store => store.footer);

  return (
    <PageLayout
      title={digitalAssetCollection.internalName}
      breadcrumb={<DigitalAssetCollectionPageBreadcrumb />}
      menu={<DigitalAssetCollectionPageMenu />}
      footer={footer}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const DigitalAssetCollectionPageLayout = (): JSX.Element => (
  <CurrentDigitalAssetCollectionProvider>
    <DigitalAssetCollectionPageSkeleton />
  </CurrentDigitalAssetCollectionProvider>
);

export default DigitalAssetCollectionPageLayout;
