// i18nOwl-ignore [app-youtube.medias.video.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import YoutubeVideoMediaConfigComponent from '@cohort/merchants/apps/youtube/medias/video/ConfigComponent';
import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';
import {YoutubeVideoMediaSpec} from '@cohort/shared/apps/youtube/medias/video';

export const YoutubeVideoMedia: ContentMedia<YoutubeVideoMediaStruct> = {
  spec: YoutubeVideoMediaSpec,
  configComponent: YoutubeVideoMediaConfigComponent,
  getTitle: t => t('medias.video.title', {ns: 'app-youtube'}),
};
