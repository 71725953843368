import Button from '@cohort/merchants/components/buttons/Button';
import Form from '@cohort/merchants/components/form/Form';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {contentsKeys} from '@cohort/merchants/hooks/api/Contents';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {createContent} from '@cohort/merchants/lib/api/Contents';
import {getContentEditRoute} from '@cohort/merchants/lib/Pages';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateContentModalProps {
  onClose: () => void;
}

export const ContentInternalNameFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
});

type ContentInternalNameFormValues = z.infer<typeof ContentInternalNameFormSchema>;

const CreateContentModal: React.FC<CreateContentModalProps> = ({onClose}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'contents.overview.createContentModal'});

  const {isLoading, mutate: createContentMutation} = useCohortMutation({
    mutationFn: async (data: ContentInternalNameFormValues) => createContent(merchant.id, data),
    onSuccess: newContent => {
      queryClient.invalidateQueries(contentsKeys.contents);
      navigate(getContentEditRoute(newContent.id).path);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent displayCloseButton={false}>
        <Form
          className="p-6"
          testId="create-content-modal"
          formSchema={ContentInternalNameFormSchema}
          defaultValues={{
            internalName: t('placeholderInternalName'),
          }}
          isSubmitting={isLoading}
          onSubmit={data => createContentMutation(data)}
          formActions={[
            <Button className="h-10 w-full" type="submit" disabled={false}>
              {t('buttonCreate')}
            </Button>,
          ]}
        >
          {({register, control}) => (
            <Fragment>
              <div className="flex flex-col justify-center pb-4 text-center">
                <DialogTitle className="text-lg">{t('create')}</DialogTitle>
              </div>
              <div className="mb-6 mt-4">
                <Input
                  type="text"
                  name="internalName"
                  register={register}
                  control={control}
                  description={t('contextInternalName')}
                />
              </div>
            </Fragment>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContentModal;
