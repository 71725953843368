import type {
  AirdropActivityADto,
  GetAirdropActivitiesParamsADto,
} from '@cohort/admin-schemas/airdropActivities';
import {AirdropActivityASchema} from '@cohort/admin-schemas/airdropActivities';
import type {
  CampaignADto,
  CampaignRevenueADto,
  CreateCampaignADto,
  CreateRedeemCodesResponseADto,
  GetCampaignsParamsADto,
  PatchCampaignADto,
  RedeemCodeADto,
} from '@cohort/admin-schemas/campaign';
import {
  CampaignASchema,
  CampaignRevenueASchema,
  CreateRedeemCodesResponseASchema,
  RedeemCodeASchema,
} from '@cohort/admin-schemas/campaign';
import type {
  ChallengeStepADto,
  UpdateChallengeStepsADto,
} from '@cohort/admin-schemas/challengeSteps';
import {ChallengeStepsResponseASchema} from '@cohort/admin-schemas/challengeSteps';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto, ExportFormatDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto, PaginationParamsDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getCampaigns(
  merchantId: string,
  params: GetCampaignsParamsADto
): Promise<[PaginationDto, CampaignADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(CampaignASchema),
    params,
  });
}

export async function getCampaign(merchantId: string, campaignId: string): Promise<CampaignADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns/${campaignId}`, {
    expect: HttpCodes.SUCCESS,
    parser: CampaignASchema.parse,
  });
}

export async function createCampaign(
  merchantId: string,
  data: CreateCampaignADto
): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
    body: data,
  });
}

export async function patchCampaign(
  merchantId: string,
  campaignId: string,
  data: PatchCampaignADto
): Promise<CampaignADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/campaigns/${campaignId}`, {
    expect: HttpCodes.SUCCESS,
    parser: CampaignASchema.parse,
    body: data,
  });
}

export async function archiveCampaign(
  merchantId: string,
  campaignId: string
): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/archive`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
  });
}

export async function deleteCampaign(merchantId: string, campaignId: string): Promise<{}> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/campaigns/${campaignId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function unarchiveCampaign(
  merchantId: string,
  campaignId: string
): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/unarchive`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
  });
}

export async function endCampaign(merchantId: string, campaignId: string): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/end`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
  });
}

export async function reopenCampaign(
  merchantId: string,
  campaignId: string
): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/reopen`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
  });
}

export async function publishCampaign(
  merchantId: string,
  campaignId: string
): Promise<CampaignADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/publish`, {
    expect: HttpCodes.CREATED,
    parser: CampaignASchema.parse,
  });
}

export async function getCampaignRevenue(
  merchantId: string,
  campaignId: string
): Promise<CampaignRevenueADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns/${campaignId}/revenue`, {
    expect: HttpCodes.SUCCESS,
    parser: CampaignRevenueASchema.parse,
  });
}

export async function getRedeemCodes(
  merchantId: string,
  campaignId: string,
  paginationParams: PaginationParamsDto
): Promise<[PaginationDto, RedeemCodeADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns/${campaignId}/redeem-codes`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(RedeemCodeASchema),
    params: paginationParams,
  });
}

export async function exportRedeemCodes(
  merchantId: string,
  campaignId: string,
  format: ExportFormatDto
): Promise<ExportDto> {
  return apiCall(
    'POST',
    `/v1/merchants/${merchantId}/campaigns/${campaignId}/redeem-codes/export`,
    {
      expect: HttpCodes.CREATED,
      parser: ExportSchema.parse,
      params: {
        format,
      },
    }
  );
}

export async function deleteRedeemCode(
  merchantId: string,
  campaignId: string,
  redeemCodeId: string
): Promise<{}> {
  return apiCall(
    'DELETE',
    `/v1/merchants/${merchantId}/campaigns/${campaignId}/redeem-codes/${redeemCodeId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: () => ({}),
    }
  );
}

export async function createRedeemCodes(
  merchantId: string,
  campaignId: string,
  quantity: number
): Promise<CreateRedeemCodesResponseADto> {
  return apiCall(
    'POST',
    `/v1/merchants/${merchantId}/campaigns/${campaignId}/generate-redeem-codes`,
    {
      expect: HttpCodes.CREATED,
      parser: CreateRedeemCodesResponseASchema.parse,
      body: {
        quantity,
      },
    }
  );
}

export async function getChallengeSteps(
  merchantId: string,
  campaignId: string
): Promise<Array<ChallengeStepADto>> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns/${campaignId}/challenge-steps`, {
    expect: HttpCodes.SUCCESS,
    parser: ChallengeStepsResponseASchema.parse,
  });
}

export async function updateChallengeSteps(
  merchantId: string,
  campaignId: string,
  body: UpdateChallengeStepsADto
): Promise<Array<ChallengeStepADto>> {
  return apiCall('POST', `/v1/merchants/${merchantId}/campaigns/${campaignId}/challenge-steps`, {
    expect: HttpCodes.CREATED,
    parser: ChallengeStepsResponseASchema.parse,
    body,
  });
}

export async function getAirdropActivities(
  merchantId: string,
  campaignId: string,
  params: GetAirdropActivitiesParamsADto
): Promise<[PaginationDto, AirdropActivityADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/campaigns/${campaignId}/airdrop-activities`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(AirdropActivityASchema),
    params,
  });
}
