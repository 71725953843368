import type {MediaSpec} from '@cohort/shared/apps/media';
import {LocalizedRichTextSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortArticleMediaConfigSchema = z.object({
  content: LocalizedRichTextSchema,
});
export type CohortArticleMediaConfig = z.infer<typeof CohortArticleMediaConfigSchema>;

export type CohortArticleMediaStruct = {
  Kind: 'cohort.article';
  Config: CohortArticleMediaConfig;
};
export const CohortArticleMediaSpec: MediaSpec<CohortArticleMediaStruct> = {
  kind: 'cohort.article',
  configSchema: CohortArticleMediaConfigSchema,
};
