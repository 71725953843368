import {
  parseIframeCode,
  SPOTIFY_EMBED_URL_SCHEMAS,
  SpotifyEmbedTypesSchema,
  SpotifyPlaylistIframeSchema,
} from '@cohort/merchants/apps/spotify/utils';
import Textarea from '@cohort/merchants/components/form/textarea/Textarea';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {zodResolver} from '@hookform/resolvers/zod';
import {useCallback, useEffect} from 'react';
import {useForm, useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isString} from 'remeda';
import {z, ZodError} from 'zod';

const generateStringifiedIframeCode = (
  playlistId: string,
  playlistTheme: unknown,
  withSize = false
): string => {
  const themeParam = isString(playlistTheme) ? `?theme=${playlistTheme}` : '';
  return `<iframe
      src=${SPOTIFY_EMBED_URL_SCHEMAS['playlist']}${playlistId}${themeParam}
      allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"${withSize ? `width="100%" height="352"` : ''} />`;
};

const SpotifyPlaylistEmbedCodeSchema = z.object({
  embedCode: z.string(),
});
type SpotifyPlaylistEmbedCodeValues = z.infer<typeof SpotifyPlaylistEmbedCodeSchema>;

const SpotifyPlaylistMediaConfigComponent: React.FC = () => {
  const {watch, setValue} = useFormContext<ContentSettingsStepValues>();
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'medias.playlist.configComponent',
  });

  const playlistId = watch('media.config.playlistId');
  const playlistTheme = watch('media.config.playlistTheme');

  const {
    register: registerEmbedCode,
    control: controlEmbedCode,
    setError: setEmbedCodeError,
    clearErrors: clearEmbedCodeErrors,
  } = useForm<SpotifyPlaylistEmbedCodeValues>({
    defaultValues: {
      embedCode: isString(playlistId)
        ? generateStringifiedIframeCode(playlistId, playlistTheme)
        : '',
    },
    resolver: zodResolver(SpotifyPlaylistEmbedCodeSchema),
  });

  const embedCodeInput = useWatch({
    control: controlEmbedCode,
    name: 'embedCode',
  });

  const updateFormValues = useCallback(
    (iframe: HTMLIFrameElement): void => {
      const src = iframe.getAttribute('src');
      const playlistId = src?.split('playlist/')[1]?.split('?')[0];
      const playlistUrl = src ? new URL(src) : null;
      const playlistTheme = playlistUrl?.searchParams.get('theme') ?? null;

      setValue('media.config.playlistId', playlistId);
      setValue('media.config.playlistUrl', playlistUrl?.href);
      setValue('media.config.playlistTheme', playlistTheme);
      setValue('media.config.playlistThumbnailUrl', null);
    },
    [setValue]
  );

  useEffect(() => {
    try {
      SpotifyPlaylistIframeSchema.parse(embedCodeInput);
      const iframe = parseIframeCode(embedCodeInput);
      if (!iframe) {
        return;
      }

      clearEmbedCodeErrors('embedCode');
      return updateFormValues(iframe);
    } catch (errors: unknown) {
      if (errors instanceof ZodError) {
        const message = errors.issues[0]?.message;
        if (SpotifyEmbedTypesSchema.safeParse(message).success) {
          setEmbedCodeError('embedCode', {
            type: 'manual',
            // i18nOwl-ignore [albumErrorEmbedUrl, artistErrorEmbedUrl, episodeErrorEmbedUrl, showErrorEmbedUrl, trackErrorEmbedUrl]
            message: t(`${message}ErrorEmbedUrl`),
          });
        } else {
          setEmbedCodeError('embedCode', {
            type: 'manual',
            message: t('defaultEmbedCodeError'),
          });
        }
      }
      setValue('media.config.playlistUrl', undefined);
    }
  }, [clearEmbedCodeErrors, embedCodeInput, setEmbedCodeError, setValue, t, updateFormValues]);

  return (
    <div className="flex flex-col space-y-4">
      <Textarea
        name="embedCode"
        placeholder={t('embedCodePlaceholder')}
        rows={4}
        register={registerEmbedCode}
        control={controlEmbedCode}
      />
      {isString(playlistId) && (
        <div
          className="rounded-xl"
          dangerouslySetInnerHTML={{
            __html: generateStringifiedIframeCode(playlistId, playlistTheme, true),
          }}
        />
      )}
    </div>
  );
};

export default SpotifyPlaylistMediaConfigComponent;
