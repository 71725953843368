import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbLink,
  BreadcrumbTitle,
} from '@cohort/merchants/components/Breadcrumb';
import {SyncStatusBadge} from '@cohort/merchants/components/syncs/SyncStatusBadge';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentSync} from '@cohort/merchants/hooks/contexts/currentSync';
import {getAppConnectionsRoute, getAppsRoute, getAppSyncsRoute} from '@cohort/merchants/lib/Pages';
import SyncDropdown from '@cohort/merchants/pages/apps/app/syncs/edit/SyncDropdown';
import type {AppId} from '@cohort/shared/apps';
import React from 'react';
import {useTranslation} from 'react-i18next';

const SyncEditPageBreadcrumb: React.FC = () => {
  const {app, appSpec} = useCurrentApp();
  const {sync} = useCurrentSync();
  const {t} = useTranslation('pages', {
    keyPrefix: 'apps.app.syncs.edit.syncEditPageBreadcrumb',
  });
  const appId = appSpec.id as AppId;

  return (
    <Breadcrumb>
      <BreadcrumbContent backPath={getAppSyncsRoute(appId).path}>
        <BreadcrumbLink name={t('name')} path={getAppsRoute().path} />
        <BreadcrumbLink
          name={
            <div className="flex items-center gap-2">
              {React.cloneElement(app.logo, {height: 24, width: 24})}
              {appSpec.name}
            </div>
          }
          path={getAppConnectionsRoute(appId).path}
        />
        <BreadcrumbTitle>{t('title', {appName: appSpec.name})}</BreadcrumbTitle>
        <BreadcrumbBadges>
          <SyncStatusBadge size="small" status={sync.status} />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <SyncDropdown sync={sync} />
    </Breadcrumb>
  );
};

export default SyncEditPageBreadcrumb;
