import Button from '@cohort/merchants/components/buttons/Button';
import CohortFormQuestionTypeLabel from '@cohort/merchants/components/form/cohortForm/CohortFormQuestionTypeLabel';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import Input from '@cohort/merchants/components/form/input/Input';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import LocalizedTextarea from '@cohort/merchants/components/form/textarea/LocalizedTextarea';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import Separator from '@cohort/merchants/components/Separator';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {Language} from '@cohort/shared/schema/common';
import {LanguageSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {
  CohortFormQuestion,
  CohortFormQuestionType,
} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormOptionSchema,
  CohortFormQuestionTypeSchema,
} from '@cohort/shared/schema/common/cohortForm';
import type {UserPropertyDataType} from '@cohort/shared/schema/common/userProperty';
import {zodResolver} from '@hookform/resolvers/zod';
import {PlusCircle, X} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {Control, FormState, UseFormRegister} from 'react-hook-form';
import {get, useController, useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';
import {v4} from 'uuid';
import {z} from 'zod';

const CohortFormQuestionSchema = z
  .object({
    id: z.string().uuid(),
    referenceId: z
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    name: LocalizedStringSchema,
    type: CohortFormQuestionTypeSchema,
    options: z.array(CohortFormOptionSchema).nullable(),
    mandatory: z.boolean().default(false),
    hasSyncedProperty: z.boolean(),
    userPropertyId: z
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    selectedLanguage: LanguageSchema.optional(),
    defaultLanguage: LanguageSchema.optional(),
  })
  .transform(data => {
    if (data.hasSyncedProperty === false) {
      data.userPropertyId = null;
    }
    return data;
  })
  .superRefine(({type, options}, ctx) => {
    if (type === 'select' && (options === null || options.length === 0)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorNoOptions',
        path: ['options'],
      });
    }
  })
  .superRefine(({selectedLanguage, defaultLanguage, name}, ctx) => {
    if (
      selectedLanguage &&
      selectedLanguage === defaultLanguage &&
      name[selectedLanguage] === undefined
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['name'],
      });
    }
  })
  .superRefine(({selectedLanguage, defaultLanguage, options}, ctx) => {
    if (selectedLanguage && selectedLanguage === defaultLanguage && options !== null) {
      for (let i = 0; i < options.length; i++) {
        if (options[i]?.label[selectedLanguage] === undefined)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errorRequired',
            path: [`options.${i}.label`],
          });
      }
    }
  });
export type CohortFormQuestionForm = z.infer<typeof CohortFormQuestionSchema>;

type UserPropertyFormSectionProps = {
  control: Control<CohortFormQuestionForm>;
  register: UseFormRegister<CohortFormQuestionForm>;
};
const UserPropertyFormSection: React.FC<UserPropertyFormSectionProps> = ({register, control}) => {
  const merchant = useCurrentMerchant();
  const [search, setSearch] = useState('');
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormQuestionSheet',
  });

  const {field: hasSyncedProperty} = useController({name: 'hasSyncedProperty', control});
  const {field: questionType} = useController({name: 'type', control});

  const userPropertyType = match(questionType.value)
    .with('number', () => 'number' as const)
    .with('text', () => 'string' as const)
    .with('email', () => 'string' as const)
    .with('date', () => 'date' as const)
    .with('checkbox', () => 'boolean' as const)
    .with('select', () => 'string' as const)
    .exhaustive() satisfies UserPropertyDataType;

  // user properties are searchable because pagination can't be done in a selector
  const {data} = usePaginatedUserProperties(merchant.id, {
    page: 1,
    pageSize: 20,
    search: search === '' ? undefined : search,
    dataTypes: [userPropertyType],
    hasAppId: false,
    orderBy: 'name',
  });
  const [, userProperties] = data ?? [];

  const userPropertiesOptions =
    userProperties?.map(userProperty => ({
      label: userProperty.name,
      value: userProperty.id,
    })) ?? [];

  return (
    <Fragment>
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow flex-col gap-1">
          <label className="block text-sm font-medium text-slate-700">
            {t('titleSyncProperty')}
          </label>
          <label className="text-xs text-slate-500">{t('subtitleSyncProperty')}</label>
        </div>
        <div className="w-10">
          <SwitchInput name="hasSyncedProperty" register={register} control={control} />
        </div>
      </div>
      {hasSyncedProperty.value === true && (
        <SelectInput
          options={userPropertiesOptions}
          name="userPropertyId"
          label={t('labelSelectProperty')}
          register={register}
          control={control}
          onInputChange={setSearch}
          inputChangeMode="debounced"
        />
      )}
    </Fragment>
  );
};

type MandatoryFormSectionProps = {
  control: Control<CohortFormQuestionForm>;
  register: UseFormRegister<CohortFormQuestionForm>;
};

const MandatoryFormSection: React.FC<MandatoryFormSectionProps> = ({register, control}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormQuestionSheet',
  });
  return (
    <div className="flex w-full items-center justify-between ">
      <div className="flex flex-grow flex-col gap-1">
        <label className="block text-sm font-medium text-slate-700">{t('titleMandatory')}</label>
        <label className="text-xs text-slate-500">{t('subtitleMandatory')}</label>
      </div>
      <div className="w-10">
        <SwitchInput name="mandatory" register={register} control={control} />
      </div>
    </div>
  );
};

type SelectOptionsFormSectionProps = {
  selectedLanguage: Language;
  control: Control<CohortFormQuestionForm>;
  formState: FormState<CohortFormQuestionForm>;
};
const SelectOptionsFormSection: React.FC<SelectOptionsFormSectionProps> = ({
  selectedLanguage,
  formState,
  control,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormQuestionSheet',
  });

  const optionsError = get(formState, `errors.options`);
  const {fields, append, remove, update, replace} = useFieldArray({
    name: `options`,
    control,
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('labelOptions')}</label>
      <DraggableList
        handleOnReorder={ids => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          replace(ids.map(id => fields[parseInt(id, 10)]!));
        }}
        items={fields.map((option, index) => {
          const error = get(formState, `errors.options.${index}.label.message`);

          return {
            item: (
              <DraggableListItem
                onRemove={
                  isDefaultLanguageSelected
                    ? () => {
                        remove(index);
                      }
                    : undefined
                }
                className="h-16 bg-slate-50"
              >
                <div className="flex w-full items-center">
                  <input
                    className="w-full rounded-md border border-border bg-white p-2 text-sm  focus:ring-primary"
                    placeholder={option.label[merchant.defaultLanguage] ?? ''}
                    value={option.label[selectedLanguage] ?? ''}
                    onChange={e => {
                      const updatedOption = {...option};
                      updatedOption.label = updateLocalizedString(
                        selectedLanguage,
                        option.label,
                        e.target.value
                      );
                      if (selectedLanguage === merchant.defaultLanguage) {
                        updatedOption.value = e.target.value;
                      }
                      update(index, updatedOption);
                    }}
                  />
                  {error && (
                    <span className="m-2 text-sm font-normal text-red-500">{t(error)}</span>
                  )}
                </div>
              </DraggableListItem>
            ),
            id: String(index),
          };
        })}
      />
      {optionsError?.message !== undefined && (
        <span className="text-sm font-normal text-red-500">{t(optionsError.message)}</span>
      )}
      {isDefaultLanguageSelected && (
        <Button onClick={() => append({label: {}, value: ''})} variant="ghost" className="w-fit">
          <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonAddOption')}
        </Button>
      )}
    </div>
  );
};

type CohortFormQuestionSheetProps = {
  title: string;
  question?: CohortFormQuestion;
  onClose: () => void;
  onSave: (data: CohortFormQuestionForm) => void;
  selectedLanguage: Language;
};

const CohortFormQuestionSheet: React.FC<CohortFormQuestionSheetProps> = ({
  title,
  question,
  onClose,
  onSave,
  selectedLanguage,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormQuestionSheet',
  });

  const {register, control, handleSubmit, watch, setValue, formState} =
    useForm<CohortFormQuestionForm>({
      defaultValues: {
        id: question?.id ?? v4(),
        referenceId: question?.referenceId ?? null,
        name: question?.name ?? {},
        type: question?.type ?? 'text',
        options: question?.options ?? null,
        mandatory: question?.mandatory ?? false,
        hasSyncedProperty: !(question === undefined || question.userPropertyId === null),
        userPropertyId: question?.userPropertyId ?? null,
        selectedLanguage: selectedLanguage,
        defaultLanguage: merchant.defaultLanguage,
      },
      resolver: zodResolver(CohortFormQuestionSchema),
    });
  const [type, name] = watch(['type', 'name']);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const questionTypes = [
    'text',
    'number',
    'email',
    'select',
    'date',
    'checkbox',
  ] satisfies Array<CohortFormQuestionType>;

  const questionTypesOptions = questionTypes.map(type => ({
    label: <CohortFormQuestionTypeLabel type={type} textClassName="text-slate-900" />,
    value: type,
  }));

  // i18nOwl-ignore [errorNoOptions, errorRequired]
  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{title}</SheetTitle>
        <form
          id="perk-form-question"
          className="flex flex-col gap-6 overflow-y-auto p-6"
          onSubmit={e => {
            // otherwise it will submit the main perk form (dunno why since i'm using the form attribute in the button)
            e.stopPropagation();
            handleSubmit(data => onSave(data))(e);
          }}
        >
          {isDefaultLanguageSelected && (
            <RadioCards
              name="type"
              direction="row"
              label={t('labelType')}
              register={register}
              control={control}
              className="grid grid-cols-3 gap-4"
              options={questionTypesOptions}
              withCheckIcon={false}
              onChange={() => {
                setValue('options', null);
                setValue('userPropertyId', null);
              }}
            />
          )}
          <LocalizedTextarea
            name="name"
            label={t('titleQuestion')}
            placeholder={name[merchant.defaultLanguage] ?? t('placeholderQuestion')}
            rows={3}
            register={register}
            control={control}
            selectedLanguage={selectedLanguage}
          />

          {type === 'select' && (
            <SelectOptionsFormSection
              selectedLanguage={selectedLanguage}
              formState={formState}
              control={control}
            />
          )}
          {isDefaultLanguageSelected && (
            <Fragment>
              <Separator />
              <MandatoryFormSection register={register} control={control} />
              <Separator />
              <UserPropertyFormSection register={register} control={control} />
              <Separator />
              <Input
                type="text"
                name="referenceId"
                label={t('labelReferenceId')}
                register={register}
                control={control}
              />
            </Fragment>
          )}
        </form>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose} data-testid="cancel">
            <X className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonCancel')}
          </Button>
          <Button type="submit" form="perk-form-question">
            {question ? t('buttonEdit') : t('buttonAdd')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default CohortFormQuestionSheet;
