import {cn} from '@cohort/shared-frontend/utils/classNames';

type TriggerMediaCardWrapperProps = {
  hasMedia: boolean;
  children: React.ReactNode;
};

const TriggerMediaCardWrapper: React.FC<TriggerMediaCardWrapperProps> = ({hasMedia, children}) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col space-y-4 overflow-hidden rounded-lg bg-white p-4 shadow-sm',
        !hasMedia ? 'size-[120px] items-center justify-center' : 'h-[270px]'
      )}
    >
      {children}
    </div>
  );
};

export default TriggerMediaCardWrapper;
