import type {CreateAirdropDataADto, GetCurrentAirdropADto} from '@cohort/admin-schemas/airdrops';
import {GetCurrentAirdropASchema} from '@cohort/admin-schemas/airdrops';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function getCurrentStandaloneAirdrop(
  merchantId: string
): Promise<GetCurrentAirdropADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/airdrops/current`, {
    expect: HttpCodes.SUCCESS,
    parser: GetCurrentAirdropASchema.parse,
  });
}

export async function createStandaloneAirdrop(
  merchantId: string,
  data: CreateAirdropDataADto
): Promise<{}> {
  return apiCall('POST', `/v1/merchants/${merchantId}/airdrops`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
    body: data,
  });
}
