// i18nOwl-ignore [app-cohort.medias.article.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortArticleMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/article/ConfigComponent';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import {CohortArticleMediaSpec} from '@cohort/shared/apps/cohort/medias/article';

export const CohortMediaArticle: ContentMedia<CohortArticleMediaStruct> = {
  spec: CohortArticleMediaSpec,
  configComponent: CohortArticleMediaConfigComponent,
  getTitle: t => t('medias.article.title', {ns: 'app-cohort'}),
};
