// i18nOwl-ignore [app-cohort.medias.video.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortVideoMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/video/ConfigComponent';
import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';
import {CohortVideoMediaSpec} from '@cohort/shared/apps/cohort/medias/video';

export const CohortMediaVideo: ContentMedia<CohortVideoMediaStruct> = {
  spec: CohortVideoMediaSpec,
  configComponent: CohortVideoMediaConfigComponent,
  getTitle: t => t('medias.video.title', {ns: 'app-cohort'}),
};
