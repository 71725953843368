import type {MediaSpec} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const CohortVideoMediaConfigSchema = z.object({
  videoFileKey: z.string(),
  thumbnailFileKey: z.string(),
});
export type CohortVideoMediaConfig = z.infer<typeof CohortVideoMediaConfigSchema>;

export type CohortVideoMediaStruct = {
  Kind: 'cohort.video';
  Config: CohortVideoMediaConfig;
};

export const CohortVideoMediaSpec: MediaSpec<CohortVideoMediaStruct> = {
  kind: 'cohort.video',
  configSchema: CohortVideoMediaConfigSchema,
};
