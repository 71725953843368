import type {
  AppSpec,
  AppStruct,
  NotificationIntegrationStruct,
  PerkIntegrationSpec,
  PerkIntegrationStruct,
} from '@cohort/shared/apps/app';
import {CohortAppSpec} from '@cohort/shared/apps/cohort';
import {DiscordAppSpec} from '@cohort/shared/apps/discord';
import {GarminConnectAppSpec} from '@cohort/shared/apps/garmin-connect';
import {GoogleFitAppSpec} from '@cohort/shared/apps/google-fit';
import {InstagramAppSpec} from '@cohort/shared/apps/instagram';
import {PostgresqlAppSpec} from '@cohort/shared/apps/postgresql';
import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {SalesforceAppSpec} from '@cohort/shared/apps/salesforce';
import {ShopifyAppSpec} from '@cohort/shared/apps/shopify';
import {SpotifyAppSpec} from '@cohort/shared/apps/spotify';
import {StravaAppSpec} from '@cohort/shared/apps/strava';
import {TalonOneAppSpec} from '@cohort/shared/apps/talon-one';
import {TikTokAppSpec} from '@cohort/shared/apps/tiktok';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {TwitterAppSpec} from '@cohort/shared/apps/twitter';
import {TypeformAppSpec} from '@cohort/shared/apps/typeform';
import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {YoutubeAppSpec} from '@cohort/shared/apps/youtube';
import {CohortError} from '@cohort/shared/schema/common/errors';
import {compact, flatten} from 'remeda';
import {z} from 'zod';

// Get a list of all Apps perkIntegrationSpecs and a type for their ids
const apps = [
  CohortAppSpec,
  DiscordAppSpec,
  GarminConnectAppSpec,
  GoogleFitAppSpec,
  InstagramAppSpec,
  PostgresqlAppSpec,
  SalesforceAppSpec,
  ShopifyAppSpec,
  SpotifyAppSpec,
  StravaAppSpec,
  TalonOneAppSpec,
  TikTokAppSpec,
  TwitterAppSpec,
  TypeformAppSpec,
  YoutubeAppSpec,
] as const satisfies ReadonlyArray<AppSpec>;

export function getAppSpecs(): readonly AppSpec[] {
  return apps;
}

// AppId
export type AppId = (typeof apps)[number]['id'];
const appIds = apps.map(app => app.id) as unknown as readonly [AppId, ...AppId[]];
export const AppIdSchema = z.enum(appIds);

// EventId
export type EventId = (typeof apps)[number]['userEventSpecs'][number]['id'];
const eventIds = apps.flatMap(app =>
  app.userEventSpecs.map(event => event.id)
) as unknown as readonly [EventId, ...EventId[]];
export const EventIdSchema = z.enum(eventIds);

// User Property Id
export type UserPropertyId = (typeof apps)[number]['userPropertySpecs'][number]['id'];
const userPropertyIds = apps.flatMap(app =>
  app.userPropertySpecs.map(userProperty => userProperty.id)
) as unknown as readonly [UserPropertyId, ...UserPropertyId[]];
export const UserPropertyIdSchema = z.enum(userPropertyIds);

// Expirable User Property Id
export type ExpirableUserPropertyId = (typeof apps)[number]['userPropertySpecs'][number]['id'];
const expirableUserPropertyIds = apps.flatMap(app =>
  app.userPropertySpecs
    .filter(userProperty => userProperty.refreshInterval !== undefined)
    .map(userProperty => userProperty.id)
) as unknown as readonly [UserPropertyId, ...UserPropertyId[]];
export const ExpirableUserPropertyIdSchema = z.enum(expirableUserPropertyIds);

// NotificationIntegrationId
export type NotificationIntegrationId =
  (typeof apps)[number]['notificationIntegrationSpecs'][number]['id'];
const notificationIntegrationIds = apps.flatMap(app =>
  app.notificationIntegrationSpecs.map(notificationIntegration => notificationIntegration.id)
) as unknown as readonly [NotificationIntegrationId, ...NotificationIntegrationId[]];
export const NotificationIntegrationIdSchema = z.enum(notificationIntegrationIds);

// ConnectorId
export type MerchantConnectorId = NonNullable<(typeof apps)[number]['merchantConnector']>['id'];
export const userConnectorIds = compact(apps.map(app => app.userConnector?.id));
export type UserConnectorId = NonNullable<(typeof apps)[number]['userConnector']>['id'];
export type ConnectorId = MerchantConnectorId | UserConnectorId;
export const connectorIds = apps
  .flatMap(app => [app.merchantConnector?.id, app.userConnector?.id])
  .filter(Boolean) as unknown as readonly [ConnectorId, ...ConnectorId[]];
export const ConnectorIdSchema = z.enum(connectorIds);

// Media Kinds
export type ContentMediaKind = (typeof apps)[number]['mediaSpecs'][number]['kind'];
const contentMediaKinds = apps.flatMap(app =>
  app.mediaSpecs.map(media => media.kind)
) as unknown as readonly [ContentMediaKind, ...ContentMediaKind[]];
export const MediaKindSchema = z.enum(contentMediaKinds);

export type PerkIntegrationId = (typeof apps)[number]['perkIntegrationSpecs'][number]['id'];
const perkIntegrationIds = apps.flatMap(app =>
  app.perkIntegrationSpecs.map(perkIntegration => perkIntegration.id)
) as unknown as readonly [PerkIntegrationId, ...PerkIntegrationId[]];
export const PerkIntegrationIdSchema = z.enum(perkIntegrationIds);

export type TriggerIntegrationId = (typeof apps)[number]['triggerIntegrationSpecs'][number]['id'];
const triggerIntegrationIds = apps.flatMap(app =>
  app.triggerIntegrationSpecs.map(triggerIntegration => triggerIntegration.id)
) as unknown as readonly [TriggerIntegrationId, ...TriggerIntegrationId[]];
export const TriggerIntegrationIdSchema = z.enum(triggerIntegrationIds);

export type ResourceType = (typeof apps)[number]['resourceSpecs'][number]['id'];
const resourceTypes = apps.flatMap(app =>
  app.resourceSpecs.map(resource => resource.id)
) as unknown as readonly [ResourceType, ...ResourceType[]];
export const ResourceTypeSchema = z.enum(resourceTypes);

export function getAppSpec<T extends AppStruct = AppStruct>(appId: T['Id']): AppSpec<T> {
  const app = apps.find((app: AppSpec) => app.id === appId);
  if (!app) {
    throw new CohortError('app.not-found', {appId});
  }
  return app as unknown as AppSpec<T>;
}

export function getPerkIntegrationSpecs(): readonly PerkIntegrationSpec[] {
  const appsSpecs = getAppSpecs();
  const perkIntegrationSpecs = compact(appsSpecs.flatMap(app => app.perkIntegrationSpecs));

  return perkIntegrationSpecs;
}

export function getTriggerIntegrationSpecs(): readonly TriggerIntegrationSpec[] {
  const appsSpecs = getAppSpecs();
  const triggerIntegrationSpecs = compact(appsSpecs.flatMap(app => app.triggerIntegrationSpecs));

  return triggerIntegrationSpecs;
}

export function getPerkIntegrationSpec<T extends PerkIntegrationStruct>(
  perkIntegrationId: T['Id']
): PerkIntegrationSpec<T> {
  const perkIntegrationSpecs = getPerkIntegrationSpecs();
  const perkIntegration = perkIntegrationSpecs.find(
    perkIntegration => perkIntegration.id === perkIntegrationId
  );

  if (!perkIntegration) {
    throw new CohortError('perk-integration.not-found', {perkIntegrationId});
  }
  return perkIntegration as unknown as PerkIntegrationSpec<T>;
}

export function getTriggerIntegrationSpec<T extends TriggerIntegrationStruct>(
  triggerIntegrationId: T['Id']
): TriggerIntegrationSpec<T> {
  const triggerIntegrationSpecs = getTriggerIntegrationSpecs();
  const triggerIntegration = triggerIntegrationSpecs.find(
    triggerIntegration => triggerIntegration.id === triggerIntegrationId
  );

  if (!triggerIntegration) {
    throw new CohortError('trigger-integration.not-found', {triggerIntegrationId});
  }
  return triggerIntegration as unknown as TriggerIntegrationSpec<T>;
}

export function getNotificationIntegrationSpec<T extends NotificationIntegrationStruct>(
  notificationIntegrationId: T['Id']
): AppSpec['notificationIntegrationSpecs'][number] {
  const appsSpecs = getAppSpecs();
  const notificationIntegrationSpecs = compact(
    appsSpecs.flatMap(app => app.notificationIntegrationSpecs)
  );
  const notificationIntegration = notificationIntegrationSpecs.find(
    notificationIntegration => notificationIntegration.id === notificationIntegrationId
  );

  if (!notificationIntegration) {
    throw new CohortError('notification-integration.not-found', {notificationIntegrationId});
  }
  return notificationIntegration;
}

export function getUserEventSpecs(): UserEventSpec[] {
  return flatten(apps.map(app => app.userEventSpecs));
}

export function getUserEventSpec<UE extends UserEventStruct>(eventId: UE['Id']): UserEventSpec<UE> {
  const userEvent = getUserEventSpecs().find(event => event.id === eventId);
  if (!userEvent) {
    throw new CohortError('user-event.invalid-event-type', {eventId});
  }
  return userEvent as UserEventSpec<UE>;
}

export function getAppUserPropertySpec<UP extends UserPropertyStruct>(
  userPropertyId: UP['Id']
): AppSpec['userPropertySpecs'][number] | null {
  const userPropertySpecs = flatten(apps.map(app => app.userPropertySpecs));
  const userProperty = userPropertySpecs.find(userProperty => userProperty.id === userPropertyId);
  return userProperty ?? null;
}

function getAllAppUserPropertySpecs(): UserPropertySpec[] {
  return flatten(apps.map(app => app.userPropertySpecs));
}

export function getAppUserPropertySpecsWithDefaultValue(): UserPropertySpec[] {
  return getAllAppUserPropertySpecs().filter(
    userProperty => userProperty.defaultValue !== undefined
  );
}

export function getAppUserPropertySpecOrThrow<UP extends UserPropertyStruct>(
  userPropertyId: UP['Id']
): AppSpec['userPropertySpecs'][number] {
  const userProperty = getAppUserPropertySpec(userPropertyId);
  if (!userProperty) {
    throw new CohortError('user-property.not-found', {userPropertyId});
  }
  return userProperty;
}

export function getAppIdFromUserPropertyId<UP extends UserPropertyStruct>(
  userPropertyId: UP['Id']
): AppId {
  const userProperty = getAppUserPropertySpecOrThrow(userPropertyId);
  return userProperty.id.split('.')[0] as AppId;
}

export function getRulesEngineUserEventIds(): EventId[] {
  return apps.flatMap(app =>
    app.userEventSpecs.filter(event => event.rulesEngineConfig.isVisible).map(event => event.id)
  );
}

export function getResourceSpec<R extends ResourceStruct>(resourceId: R['Id']): ResourceSpec<R> {
  const resourceSpecs = flatten(apps.map(app => app.resourceSpecs));
  const resource = resourceSpecs.find(resource => resource.id === resourceId);
  if (!resource) {
    throw new CohortError('resource.not-found', {resourceId});
  }
  return resource as ResourceSpec<R>;
}

export function getUserPropertySpecFromId(userPropertyId: UserPropertyId): UserPropertySpec {
  const userPropertySpecs = flatten(apps.map(app => app.userPropertySpecs));
  const userProperty = userPropertySpecs.find(userProperty => userProperty.id === userPropertyId);
  if (!userProperty) {
    throw new CohortError('user-property.not-found', {userPropertyId});
  }
  return userProperty;
}
