import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import {
  getCampaignActivityRoute,
  getCampaignAudienceRoute,
  getCampaignNotificationRoute,
  getCampaignRewardRoute,
  getCampaignSettingsRoute,
} from '@cohort/merchants/lib/Pages';
import {Bell, Gear, Gift, HouseLine, Users} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const CampaignPageMenu = (): JSX.Element => {
  const campaign = useCurrentCampaign();
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageMenu'});
  const menu = useCampaignPageStore(state => state.menu);

  if (menu) {
    return <Fragment>{menu}</Fragment>;
  }

  const settingsAnchors = match(campaign.type)
    .with('challenge', () => [
      {
        name: t('appearance'),
        value: 'appearance',
      },
      {
        name: t('steps'),
        value: 'steps',
      },
      {
        name: t('challengeOptions'),
        value: 'options',
      },
      {
        name: t('customerInformation'),
        value: 'customer-information',
      },
    ])
    .with('store', () => [
      {
        name: t('appearance'),
        value: 'appearance',
      },
      {
        name: t('price'),
        value: 'price',
      },
      {
        name: t('storeOptions'),
        value: 'options',
      },
      {
        name: t('customerInformation'),
        value: 'customer-information',
      },
    ])
    .otherwise(() => []);

  return (
    <AsidePageMenu
      entries={[
        ...(['published', 'ended'].includes(campaign.status)
          ? [
              {
                name: t('activity'),
                path: getCampaignActivityRoute(campaign.id).path,
                icon: <HouseLine size={20} />,
                testId: 'activity-link',
              },
              {
                name: t('audience'),
                path: getCampaignAudienceRoute(campaign.id).path,
                icon: <Users size={20} />,
                testId: 'audience-link',
              },
              {
                name: t('reward'),
                path: getCampaignRewardRoute(campaign.id).path,
                icon: <Gift size={20} />,
                testId: 'reward-link',
              },
              // @Devs - We do not want to show the notifications link for airdrop campaigns.
              ...(campaign.type !== 'airdrop'
                ? [
                    {
                      name: t('notifications'),
                      path: getCampaignNotificationRoute(campaign.id).path,
                      icon: <Bell size={20} />,
                      testId: 'notifications-link',
                    },
                  ]
                : []),
            ]
          : []),
        {
          name: t('settings'),
          path: getCampaignSettingsRoute(campaign.id).path,
          icon: <Gear size={20} />,
          testId: 'settings-link',
          anchors: settingsAnchors,
        },
      ]}
    />
  );
};

export default CampaignPageMenu;
