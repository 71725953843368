import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import ExportUsersConfig from '@cohort/merchants/apps/salesforce/sync/ExportUsersConfig';
import ImportUsersConfig from '@cohort/merchants/apps/salesforce/sync/ImportUsersConfig';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/syncs/edit/utils';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const SalesforceUserSyncConfigComponent: React.FC<SyncConfigComponentProps> = ({sync}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.userSyncConfigComponent',
  });

  const {register, control, setValue, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const isImportEnabled = watch('userImportEnabled');

  return (
    <div className="mb-24 space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex items-center gap-2">
          <SwitchInput
            name="userImportEnabled"
            label={t('labelEnableUserImport')}
            labelPosition="right"
            register={register}
            control={control}
            onCheckedChange={check => {
              setValue('userImportEnabled', check);
              if (!check) {
                setValue('userExportEnabled', check);
              }
            }}
          />
        </div>
        <ImportUsersConfig sync={sync} />
      </div>
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex items-center gap-2">
          <SwitchInput
            name="userExportEnabled"
            label={t('labelEnableUserExport')}
            labelPosition="right"
            register={register}
            control={control}
            disabled={!isImportEnabled}
          />
        </div>
        <ExportUsersConfig sync={sync} />
      </div>
    </div>
  );
};

export default SalesforceUserSyncConfigComponent;
