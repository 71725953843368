import type {PatchPerkRequestADto} from '@cohort/admin-schemas/perk';
import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import Loader from '@cohort/merchants/components/Loader';
import PerkAirdropModal from '@cohort/merchants/components/perks/PerkAirdropModal';
import {PerkAppBadge} from '@cohort/merchants/components/perks/PerkAppBadge';
import PerkRaffleBadge from '@cohort/merchants/components/perks/PerkRaffleBadge';
import {PerkStatusBadge} from '@cohort/merchants/components/perks/PerkStatusBadge';
import {PerkTypeBadge} from '@cohort/merchants/components/perks/PerkTypeBadge';
import {useCurrentStandaloneAirdrop} from '@cohort/merchants/hooks/api/Airdrops';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchPerk} from '@cohort/merchants/lib/api/Perks';
import {getPerksRoute} from '@cohort/merchants/lib/Pages';
import {PerkInternalNameFormSchema} from '@cohort/merchants/pages/perks/new/CreatePerkModal';
import PerkDropdown from '@cohort/merchants/pages/perks/overview/PerkDropdown';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

const PerkPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.perkPageBreadcrumb'});
  const queryClient = useQueryClient();
  const perk = useCurrentPerk();
  const [airdropModalOpen, setAirdropModalOpen] = useState(false);

  const {data: currentAirdrop} = useCurrentStandaloneAirdrop(merchantId);
  const airdropInProgress = currentAirdrop?.status === 'in-progress';

  const {mutateAsync: updatePerk} = useCohortMutation({
    mutationFn: async (perkData: PatchPerkRequestADto) => patchPerk(merchantId, perk.id, perkData),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(campaignsKeys.campaigns);
      queryClient.invalidateQueries(perksKeys.perks);
    },
  });

  return (
    <Fragment>
      {airdropModalOpen && <PerkAirdropModal onClose={() => setAirdropModalOpen(false)} />}
      <Breadcrumb>
        <BreadcrumbContent>
          <BreadcrumbLink name={t('name')} path={getPerksRoute().path} />
          <BreadcrumbEditableTitle
            value={perk.internalName}
            onChange={(name: string) => updatePerk({internalName: name})}
            validator={(internalName: string) =>
              PerkInternalNameFormSchema.safeParse({internalName}).success
            }
          />
          <BreadcrumbBadges>
            <PerkStatusBadge
              key="status"
              size="small"
              status={perk.status}
              liveCount={perk.campaignsCount}
            />
            <PerkTypeBadge key="type" perkType={perk.type} />
            <PerkAppBadge
              key="app"
              perkIntegrationId={perk.integration?.perkIntegrationId ?? null}
            />
            {perk.raffle !== null ? (
              <PerkRaffleBadge key="raffle" />
            ) : (
              <Fragment key="raffle-fragment" />
            )}
          </BreadcrumbBadges>
        </BreadcrumbContent>
        <div className="flex items-center gap-4">
          <Button
            disabled={airdropInProgress}
            variant="secondary"
            onClick={() => setAirdropModalOpen(true)}
          >
            <div className="mr-1">
              {airdropInProgress ? (
                <Loader size={14} color="gray" />
              ) : (
                <PaperPlaneRight className="-rotate-90 text-primary" />
              )}
            </div>
            {t('labelAirdrop')}
          </Button>
          <PerkDropdown perk={perk} />
        </div>
      </Breadcrumb>
    </Fragment>
  );
};

export default PerkPageBreadcrumb;
