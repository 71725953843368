import SelectVideoSheet from '@cohort/merchants/apps/youtube/triggers/SelectVideoSheet';
import YoutubeVideoCard from '@cohort/merchants/apps/youtube/triggers/YoutubeVideoCard';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {YoutubeVideo} from '@cohort/shared/apps/youtube/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowsCounterClockwise, Video} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type YoutubeVideoSelectorProps = {
  showDefaultSelectButton: boolean;
  description: string;
  formReturn: UseFormReturn<StepTriggerFormType>;
  title: string;
  videos: YoutubeVideo[];
  selectedVideo?: YoutubeVideo;
};

const YoutubeVideoSelector: React.FC<YoutubeVideoSelectorProps> = ({
  showDefaultSelectButton,
  description,
  formReturn,
  selectedVideo,
  title,
  videos,
}) => {
  const [selectVideoSheetOpen, setSelectVideoSheetOpen] = useState(false);
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'triggerIntegrations.youtubeVideoSelector',
  });

  const videoWithThumbnail = selectedVideo?.thumbnailUrl !== undefined;

  return (
    <Fragment>
      <div>
        <p className="pb-1 text-sm font-medium">{title}</p>
        <p className="pb-2 text-sm">{description}</p>
        {showDefaultSelectButton ? (
          <Button variant="ghost" onClick={() => setSelectVideoSheetOpen(true)}>
            <Video className="mr-2 h-5 w-5" />
            {t('buttonSelectVideo')}
          </Button>
        ) : (
          <Fragment>
            <div
              className={cn('flex gap-5', videoWithThumbnail ? 'w-fit flex-col' : 'items-center')}
            >
              <div className="rounded-lg border border-slate-200 shadow-sm">
                <YoutubeVideoCard video={selectedVideo} />
              </div>
              <Button variant="secondary" onClick={() => setSelectVideoSheetOpen(true)}>
                <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
                {t('buttonChangeVideo')}
              </Button>
            </div>
            {!videoWithThumbnail && (
              <div className="mt-4">
                <HighlightText type="warning" text={t('videoNotFound')} />
              </div>
            )}
          </Fragment>
        )}
      </div>
      {selectVideoSheetOpen && (
        <SelectVideoSheet
          formReturn={formReturn}
          videos={videos}
          onClose={() => setSelectVideoSheetOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default YoutubeVideoSelector;
