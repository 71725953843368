import {z} from 'zod';

export const RaffleStatusSchema = z.enum(['pending', 'done']);
export type RaffleStatus = z.infer<typeof RaffleStatusSchema>;

export const PrivateContentPerkContentTypeSchema = z.enum(['video', 'pdf']);
export type PrivateContentPerkContentType = z.infer<typeof PrivateContentPerkContentTypeSchema>;

export const PerkStatusSchema = z.enum(['live', 'archived', 'draft']);
export type PerkStatus = z.infer<typeof PerkStatusSchema>;

export const RaffleInstanceStatusSchema = z.enum(['won', 'lost', 'pending']);
export type RaffleInstanceStatus = z.infer<typeof RaffleInstanceStatusSchema>;

export const PerkTypeSchema = z.enum([
  'custom',
  'limited-edition',
  'custom-product',
  'exclusive-content',
  'discount',
  'pre-sale',
  'private-sale',
  'giveaway',
  'private-community',
  'voting',
  'survey',
  'event-ticket',
  '1-1-meeting',
  'webinar',
  'livestream',
  'conciergerie',
  'VIP-support',
  'community',
]);
export type PerkType = z.infer<typeof PerkTypeSchema>;
