import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortChallengeStepCompletedPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  challengeParticipationId: z.string().uuid(),
  challengeStepCompletionId: z.string().uuid(),
  challengeStepId: z.string().uuid(),
});
export type CohortChallengeStepCompletedProperties = z.infer<
  typeof CohortChallengeStepCompletedPropertiesSchema
>;

export type CohortChallengeStepCompletedEventStruct = UserEventStruct<
  'cohort.challenge.step-completed',
  keyof CohortChallengeStepCompletedProperties,
  CohortChallengeStepCompletedProperties,
  CohortChallengeStepCompletedProperties
>;

export const CohortChallengeStepCompletedEventSpec: UserEventSpec<CohortChallengeStepCompletedEventStruct> =
  {
    id: 'cohort.challenge.step-completed',
    description: 'Event Triggerred when a user completes a step of a Challenge',
    propertiesSchema: CohortChallengeStepCompletedPropertiesSchema,
    exposedPropertiesSchema: CohortChallengeStepCompletedPropertiesSchema,
    resources: {
      campaignId: 'cohort.challenge',
    },
    rulesEngineConfig: {
      isVisible: true,
      exposedFields: ['campaignId'],
    },
  };
export default CohortChallengeStepCompletedEventSpec;
