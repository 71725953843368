import type {SyncADto} from '@cohort/admin-schemas/sync';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useSync} from '@cohort/merchants/hooks/api/Syncs';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentSyncProviderProps = {
  children: React.ReactNode;
};

export type CurrentSyncContextValue = {
  sync: SyncADto;
};

export const CurrentSyncContext = createContext<CurrentSyncContextValue | null>(null);

export const CurrentSyncProvider: React.FC<CurrentSyncProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {syncId} = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: sync, isFetched} = useSync(merchant.id, syncId!, {
    enabled: syncId !== undefined,
  });

  if (syncId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !sync) {
    return <NotFoundSection />;
  }

  if (sync === undefined) {
    return <SkeletonLoader />;
  }

  return <CurrentSyncContext.Provider value={{sync}}>{children}</CurrentSyncContext.Provider>;
};
