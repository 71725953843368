import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react';
import type {FieldError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export type WrappedField = {
  label?: string;
  description?: string | JSX.Element;

  labelPosition?: 'top' | 'left' | 'right';
  optional?: boolean;
};

export type FieldWrapperProps = WrappedField & {
  name: string;
  error?: FieldError['message'];
  children: React.ReactNode;
  className?: string;
};

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  label,
  labelPosition = 'top',
  name,
  optional,
  description,
  children,
  error,
  className,
}) => {
  const {t: translateErrors} = useTranslation('forms', {keyPrefix: 'validation'});

  // @Devs, this is a (big ugly) solution to check if the err string is a Cohort error code or a message.
  // For integrations we are storing the translations in the apps/ folders so we can't use the cohort error codes,
  // we return the translated message instead.
  const isErrorCode = (err: string): boolean => {
    const regex = /^error[A-Z]/u;
    return regex.test(err);
  };

  return (
    <Fragment>
      <div
        className={cn(
          'flex w-full',
          labelPosition === 'top' ? 'flex-col gap-2' : 'items-center gap-4',
          className
        )}
      >
        {label && (
          <FieldLabel label={label} optional={optional} name={name} labelPosition={labelPosition} />
        )}
        {children}
        {labelPosition === 'top' && error && (
          <p className="text-sm text-red-500" data-testid={`${name}-error`}>
            {isErrorCode(error) ? translateErrors(error) : error}
          </p>
        )}
        {description && <p className="text-sm text-slate-500">{description}</p>}
      </div>
      {labelPosition !== 'top' && error && (
        <p className="text-sm text-red-500" data-testid={`${name}-error`}>
          {isErrorCode(error) ? translateErrors(error) : error}
        </p>
      )}
    </Fragment>
  );
};

export default FieldWrapper;
