// i18nOwl-ignore [app-cohort.medias.link.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortLinkMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/link/ConfigComponent';
import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';
import {CohortLinkMediaSpec} from '@cohort/shared/apps/cohort/medias/link';

export const CohortMediaLink: ContentMedia<CohortLinkMediaStruct> = {
  spec: CohortLinkMediaSpec,
  configComponent: CohortLinkMediaConfigComponent,
  getTitle: t => t('medias.link.title', {ns: 'app-cohort'}),
};
