// i18nOwl-ignore [app-spotify.medias.playlist.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import SpotifyPlaylistMediaConfigComponent from '@cohort/merchants/apps/spotify/medias/playlist/ConfigComponent';
import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';
import {SpotifyPlaylistMediaSpec} from '@cohort/shared/apps/spotify/medias/playlist';

export const SpotifyPlaylistMedia: ContentMedia<SpotifyPlaylistMediaStruct> = {
  spec: SpotifyPlaylistMediaSpec,
  configComponent: SpotifyPlaylistMediaConfigComponent,
  getTitle: t => t('medias.playlist.title', {ns: 'app-spotify'}),
};
