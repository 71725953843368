import DOMPurify from 'dompurify';
import {z} from 'zod';

export const SpotifyEmbedTypesSchema = z.enum([
  'album',
  'artist',
  'episode',
  'playlist',
  'show',
  'track',
]);
export type SpotifyEmbedType = z.infer<typeof SpotifyEmbedTypesSchema>;

type SpotifyEmbedUrlSchema = {[key in SpotifyEmbedType]: string};

export const SPOTIFY_EMBED_URL_SCHEMAS: SpotifyEmbedUrlSchema = {
  album: 'https://open.spotify.com/embed/album/',
  artist: 'https://open.spotify.com/embed/artist/',
  episode: 'https://open.spotify.com/embed/episode/',
  playlist: 'https://open.spotify.com/embed/playlist/',
  show: 'https://open.spotify.com/embed/show/',
  track: 'https://open.spotify.com/embed/track/',
};

export const parseIframeCode = (iframeCode: string): HTMLIFrameElement | null => {
  const sanitizedCode = DOMPurify.sanitize(iframeCode, {
    ALLOWED_TAGS: ['iframe'],
    ALLOWED_ATTR: [
      'style',
      'src',
      'allow',
      'allowfullscreen',
      'frameborder',
      'loading',
      'width',
      'height',
    ],
  });

  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedCode, 'text/html');
  return doc.querySelector('iframe');
};

export const SpotifyPlaylistIframeSchema = z.string().refine(
  value => {
    if (!value) {
      return true;
    }
    const iframe = parseIframeCode(value);

    // Check if the element is an iframe and has a valid Spotify embed URL.
    if (iframe) {
      const src = iframe.getAttribute('src');
      if (!src) {
        return false;
      }

      if (src.startsWith(SPOTIFY_EMBED_URL_SCHEMAS.playlist)) {
        return true;
      }

      // Throw with a precise message if the URL is from Spotify but not a playlist.
      for (const [type, schema] of Object.entries(SPOTIFY_EMBED_URL_SCHEMAS)) {
        if (src.startsWith(schema)) {
          throw new z.ZodError([
            {
              code: 'custom',
              message: type,
              path: [],
            },
          ]);
        }
      }
    }
    return false;
  },
  {
    message: 'Invalid iframe or incorrect Spotify playlist URL',
  }
);
