// i18nOwl-ignore [app-cohort.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/apps/cohort/locales/en.json';
import translationFr from '@cohort/merchants/apps/cohort/locales/fr.json';
import {CohortMediaArticle} from '@cohort/merchants/apps/cohort/medias/article/Media';
import {CohortMediaImage} from '@cohort/merchants/apps/cohort/medias/image/Media';
import {CohortMediaLink} from '@cohort/merchants/apps/cohort/medias/link/Media';
import {CohortMediaVideo} from '@cohort/merchants/apps/cohort/medias/video/Media';
import {CohortCustomRequestNotificationIntegration} from '@cohort/merchants/apps/cohort/notificationIntegrations/customRequest/NotificationIntegration';
import {CohortEmailNotificationIntegration} from '@cohort/merchants/apps/cohort/notificationIntegrations/email/NotificationIntegration';
import {CohortFormPerkIntegration} from '@cohort/merchants/apps/cohort/perkIntegrations/form/PerkIntegration';
import {CohortQrCodePerkIntegration} from '@cohort/merchants/apps/cohort/perkIntegrations/qrCode/PerkIntegration';
import {CohortBeInCohortTriggerIntegration} from '@cohort/merchants/apps/cohort/triggerIntegrations/beInCohort/TriggerIntegration';
import {CohortDigitalAssetOwnedTriggerIntegration} from '@cohort/merchants/apps/cohort/triggerIntegrations/digitalAssetOwned/TriggerIntegration';
import {CohortFillFormTriggerIntegration} from '@cohort/merchants/apps/cohort/triggerIntegrations/fillForm/TriggerIntegration';
import {CohortPerkUsedTriggerIntegration} from '@cohort/merchants/apps/cohort/triggerIntegrations/perkUsed/TriggerIntegration';
import {CohortVisitLinkTriggerIntegration} from '@cohort/merchants/apps/cohort/triggerIntegrations/visitLink/TriggerIntegration';
import {CohortAppConnectedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/appConnected/UserEvent';
import {CohortAppDisconnectedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/appDisconnected/UserEvent';
import {CohortBadgeGainedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/badgeGained/UserEvent';
import {CohortBadgeLostUserEvent} from '@cohort/merchants/apps/cohort/userEvents/badgeLost/UserEvent';
import {CohortChallengeCompletedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeCompleted/UserEvent';
import {CohortChallengeLeftUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeLeft/UserEvent';
import {CohortChallengeOptedInUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeOptedIn/UserEvent';
import {CohortChallengeStepCompletedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeStepCompleted/UserEvent';
import {CohortCohortJoinedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/cohortJoined/UserEvent';
import {CohortCohortLeftUserEvent} from '@cohort/merchants/apps/cohort/userEvents/cohortLeft/UserEvent';
import {CohortDigitalAssetReceivedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/digitalAssetReceived/UserEvent';
import {CohortDigitalAssetTransferredUserEvent} from '@cohort/merchants/apps/cohort/userEvents/digitalAssetTransferred/UserEvent';
import {CohortNewChallengeAvailableUserEvent} from '@cohort/merchants/apps/cohort/userEvents/newChallengeAvailable/UserEvent';
import {CohortNewChallengeStepAvailableUserEvent} from '@cohort/merchants/apps/cohort/userEvents/newChallengeStepAvailable/UserEvent';
import {CohortPerkReceivedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkReceived/UserEvent';
import {CohortPerkRestoredUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkRestored/UserEvent';
import {CohortPerkRevokedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkRevoked/UserEvent';
import {CohortPerkUsedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkUsed/UserEvent';
import {CohortUserCreatedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/userCreated/UserEvent';
import {CohortUserDeletedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/userDeleted/UserEvent';
import {CohortUsernameChangedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/usernameChanged/UserEvent';
import {CohortXpsVisitedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/xpsVisited/UserEvent';
import type {CohortAppStruct} from '@cohort/shared/apps/cohort';
import {CohortAppSpec} from '@cohort/shared/apps/cohort';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

// i18nOwl-ignore [app-cohort.userEvents.app.connected.title]
// i18nOwl-ignore [app-cohort.userEvents.app.disconnected.title]
// i18nOwl-ignore [app-cohort.userEvents.cohort.joined.title]
// i18nOwl-ignore [app-cohort.userEvents.badge.gained.title]
// i18nOwl-ignore [app-cohort.userEvents.badge.lost.title]
// i18nOwl-ignore [app-cohort.userEvents.cohort.left.title]
// i18nOwl-ignore [app-cohort.userEvents.user.created.title]
// i18nOwl-ignore [app-cohort.userEvents.user.deleted.title]
// i18nOwl-ignore [app-cohort.userEvents.user.username-changed.title]
// i18nOwl-ignore [app-cohort.userEvents.user.xps-visited.title]
// i18nOwl-ignore [app-cohort.userEvents.digital-asset.received.title]
// i18nOwl-ignore [app-cohort.userEvents.digital-asset.transferred.detail]
// i18nOwl-ignore [app-cohort.userEvents.digital-asset.transferred.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.completed.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.left.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.new-challenge-available.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.new-step-available.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.opted-in.title]
// i18nOwl-ignore [app-cohort.userEvents.challenge.step-completed.title]
// i18nOwl-ignore [app-cohort.userEvents.perk.received.title]
// i18nOwl-ignore [app-cohort.userEvents.perk.restored.title]
// i18nOwl-ignore [app-cohort.userEvents.perk.revoked.title]
// i18nOwl-ignore [app-cohort.userEvents.perk.used.title]

const CohortApp: App<CohortAppStruct> = {
  spec: CohortAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="cohort" height={40} width={40} />,
  medias: [CohortMediaArticle, CohortMediaImage, CohortMediaLink, CohortMediaVideo],
  notificationIntegrations: [
    CohortEmailNotificationIntegration,
    CohortCustomRequestNotificationIntegration,
  ],
  perkIntegrations: [CohortFormPerkIntegration, CohortQrCodePerkIntegration],
  triggerIntegrations: [
    CohortBeInCohortTriggerIntegration,
    CohortDigitalAssetOwnedTriggerIntegration,
    CohortFillFormTriggerIntegration,
    CohortPerkUsedTriggerIntegration,
    CohortVisitLinkTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [
    CohortAppConnectedUserEvent,
    CohortAppDisconnectedUserEvent,
    CohortBadgeGainedUserEvent,
    CohortBadgeLostUserEvent,
    CohortCohortJoinedUserEvent,
    CohortCohortLeftUserEvent,
    CohortDigitalAssetReceivedUserEvent,
    CohortDigitalAssetTransferredUserEvent,
    CohortChallengeCompletedUserEvent,
    CohortNewChallengeStepAvailableUserEvent,
    CohortChallengeLeftUserEvent,
    CohortChallengeOptedInUserEvent,
    CohortChallengeStepCompletedUserEvent,
    CohortNewChallengeAvailableUserEvent,
    CohortPerkReceivedUserEvent,
    CohortPerkRestoredUserEvent,
    CohortPerkRevokedUserEvent,
    CohortPerkUsedUserEvent,
    CohortUserCreatedUserEvent,
    CohortUserDeletedUserEvent,
    CohortUsernameChangedUserEvent,
    CohortXpsVisitedUserEvent,
  ],
};

export default CohortApp;
