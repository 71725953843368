// i18nOwl-ignore [app-cohort.medias.image.title]
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortImageMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/image/ConfigComponent';
import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';
import {CohortImageMediaSpec} from '@cohort/shared/apps/cohort/medias/image';

export const CohortMediaImage: ContentMedia<CohortImageMediaStruct> = {
  spec: CohortImageMediaSpec,
  configComponent: CohortImageMediaConfigComponent,
  getTitle: t => t('medias.image.title', {ns: 'app-cohort'}),
};
