import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import {useSalesforceUserObjects} from '@cohort/merchants/apps/salesforce/actions';
import ImportUsersEmailFieldSelect from '@cohort/merchants/apps/salesforce/sync/ImportUsersEmailFieldSelect';
import ImportUsersFields from '@cohort/merchants/apps/salesforce/sync/ImportUsersFields';
import ImportUsersObjectNameSelect from '@cohort/merchants/apps/salesforce/sync/ImportUsersObjectNameSelect';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useConnection} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';

type ImportUsersConfigProps = SyncConfigComponentProps;
const ImportUsersConfig: React.FC<ImportUsersConfigProps> = ({sync}) => {
  const merchant = useCurrentMerchant();

  const {data: connection, isFetched} = useConnection(merchant.id, sync.connectionId);

  const {data: getUserObjectsResponse, isFetched: isSalesforceUserObjectsFetched} =
    useSalesforceUserObjects(merchant.id, sync.connectionId);
  const salesforceUserObjects = getUserObjectsResponse?.output.objects ?? [];

  if (isFetched && connection === undefined) {
    return <ErrorState />;
  }

  if (!isSalesforceUserObjectsFetched) {
    return <LoadingState className="bg-white" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <ImportUsersObjectNameSelect salesforceUserObjects={salesforceUserObjects} />
      <ImportUsersEmailFieldSelect salesforceUserObjects={salesforceUserObjects} />
      <ImportUsersFields salesforceUserObjects={salesforceUserObjects} />
    </div>
  );
};

export default ImportUsersConfig;
