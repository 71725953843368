import FileInput from '@cohort/merchants/components/form/FileInput';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {useFormContext} from 'react-hook-form';

const CohortVideoMediaConfigComponent: React.FC = () => {
  const {control, register} = useFormContext<ContentSettingsStepValues>();

  return (
    <FileInput
      name="media.config.videoFileKey"
      assetKind="contentVideo"
      register={register}
      control={control}
      accept={AllowedAssetMimeTypes.contentVideo.options.join(',')}
    />
  );
};

export default CohortVideoMediaConfigComponent;
