import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortUserDeletedPropertiesSchema = z.object({});
export type CohortUserDeletedProperties = z.infer<typeof CohortUserDeletedPropertiesSchema>;

export type CohortUserDeletedEventStruct = UserEventStruct<
  'cohort.user.deleted',
  keyof CohortUserDeletedProperties,
  CohortUserDeletedProperties,
  CohortUserDeletedProperties
>;

export const CohortUserDeletedEventSpec: UserEventSpec<CohortUserDeletedEventStruct> = {
  id: 'cohort.user.deleted',
  description: 'Event Triggerred when a user is deleted',
  propertiesSchema: CohortUserDeletedPropertiesSchema,
  exposedPropertiesSchema: CohortUserDeletedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
