import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const SpotifyPlaylistMediaConfigSchema = z.object({
  playlistId: z.string(),
  playlistUrl: z.string(),
  playlistThumbnailUrl: z.string().nullable(),
  playlistTheme: z.string().nullable(),
});
export type SpotifyPlaylistMediaConfig = z.infer<typeof SpotifyPlaylistMediaConfigSchema>;

export type SpotifyPlaylistMediaStruct = MediaStruct<
  'spotify.playlist',
  SpotifyPlaylistMediaConfig
>;
export const SpotifyPlaylistMediaSpec: MediaSpec<SpotifyPlaylistMediaStruct> = {
  kind: 'spotify.playlist',
  configSchema: SpotifyPlaylistMediaConfigSchema,
};
