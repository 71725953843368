import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortChallengeResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
});

export type CohortChallengeResourceType = z.infer<typeof CohortChallengeResourceSchema>;

export type CohortChallengeResourceStruct = ResourceStruct<
  'cohort.challenge',
  CohortChallengeResourceType
>;

export const CohortChallengeResourceSpec: ResourceSpec<CohortChallengeResourceStruct> = {
  id: 'cohort.challenge',
  schema: CohortChallengeResourceSchema,
};
